/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateSurgeryCenterDetails = /* GraphQL */ `
  subscription OnCreateSurgeryCenterDetails(
    $filter: ModelSubscriptionSurgeryCenterDetailsFilterInput
  ) {
    onCreateSurgeryCenterDetails(filter: $filter) {
      id
      surgeryCenterName
      searchByName
      surgeryCenterProfilePhoto
      surgeryPhotos
      surgeryMapSource
      aboutSurgeryCenter
      surgeryCenterAddLine1
      surgeryCenterAddLine2
      surgeryCenterAddLine3
      surgeryCenterZip
      surgeryCenterPhone
      surgeryCenterSpeciality
      specialty
      specailtyAndBodyPart {
        specialty
        surgeryCenterSpeciality
        __typename
      }
      surgeryCenterWebsite
      surgeryCenterPhotos
      surgeryCenterGoogleRating
      surgeryCenterFaceBookRating
      surgeryCenterEmail
      testimonial
      activeOnPortal
      distance
      location {
        lat
        lon
        __typename
      }
      byName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateSurgeryCenterDetails = /* GraphQL */ `
  subscription OnUpdateSurgeryCenterDetails(
    $filter: ModelSubscriptionSurgeryCenterDetailsFilterInput
  ) {
    onUpdateSurgeryCenterDetails(filter: $filter) {
      id
      surgeryCenterName
      searchByName
      surgeryCenterProfilePhoto
      surgeryPhotos
      surgeryMapSource
      aboutSurgeryCenter
      surgeryCenterAddLine1
      surgeryCenterAddLine2
      surgeryCenterAddLine3
      surgeryCenterZip
      surgeryCenterPhone
      surgeryCenterSpeciality
      specialty
      specailtyAndBodyPart {
        specialty
        surgeryCenterSpeciality
        __typename
      }
      surgeryCenterWebsite
      surgeryCenterPhotos
      surgeryCenterGoogleRating
      surgeryCenterFaceBookRating
      surgeryCenterEmail
      testimonial
      activeOnPortal
      distance
      location {
        lat
        lon
        __typename
      }
      byName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteSurgeryCenterDetails = /* GraphQL */ `
  subscription OnDeleteSurgeryCenterDetails(
    $filter: ModelSubscriptionSurgeryCenterDetailsFilterInput
  ) {
    onDeleteSurgeryCenterDetails(filter: $filter) {
      id
      surgeryCenterName
      searchByName
      surgeryCenterProfilePhoto
      surgeryPhotos
      surgeryMapSource
      aboutSurgeryCenter
      surgeryCenterAddLine1
      surgeryCenterAddLine2
      surgeryCenterAddLine3
      surgeryCenterZip
      surgeryCenterPhone
      surgeryCenterSpeciality
      specialty
      specailtyAndBodyPart {
        specialty
        surgeryCenterSpeciality
        __typename
      }
      surgeryCenterWebsite
      surgeryCenterPhotos
      surgeryCenterGoogleRating
      surgeryCenterFaceBookRating
      surgeryCenterEmail
      testimonial
      activeOnPortal
      distance
      location {
        lat
        lon
        __typename
      }
      byName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateEmployeeRegi = /* GraphQL */ `
  subscription OnCreateEmployeeRegi(
    $filter: ModelSubscriptionEmployeeRegiFilterInput
    $owner: String
  ) {
    onCreateEmployeeRegi(filter: $filter, owner: $owner) {
      id
      firstName
      lastName
      fcmToken
      searchByName
      employeeEmail
      emailType
      verified
      role
      userType
      emailID
      epcEmployerID
      createdAt
      inList
      lastLogin
      billing_status
      total_user
      members {
        nextToken
        __typename
      }
      dpcMembers {
        nextToken
        __typename
      }
      redeemedCoupon {
        promotionalID
        date
        __typename
      }
      promotionalID
      dpcDoctorID
      dpcDoctor {
        id
        physicianID
        doctorName
        searchByName
        byName
        doctorProfilePhoto
        doctorDegree
        doctorEmail
        aboutDoctor
        doctorEducation
        doctorOfficeAdd
        areaOfSpeciality
        bodyArea
        specialities
        doctorOfficeCity
        doctorOfficeState
        doctorOfficeZip
        doctorOfficePhone
        doctorOfficeFax
        doctorPracticeName
        doctorWebsite
        doctorFacility
        surgeryCenter
        googleRating
        facebookRating
        testimonial
        activeOnPortal
        distance
        specialty
        startDate
        isDpcDoctor
        createdAt
        updatedAt
        __typename
      }
      companyID {
        id
        approved
        name
        searchByName
        url
        phoneNumber
        email
        emailDomain
        planCategory
        profileID
        billing_status
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        createdAt
        updatedAt
        companyRegiUnSubscribeIDId
        owner
        __typename
      }
      unSubscribeID {
        id
        reason
        createdAt
        updatedAt
        unSubscribeEmployeeId
        unSubscribeMemberId
        unSubscribeCompanyId
        owner
        __typename
      }
      DpcBillingID
      DpcDetails {
        id
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        createdAt
        updatedAt
        __typename
      }
      epcCheckoutLink {
        checkoutID
        url
        expiry
        __typename
      }
      profile {
        email
        profilePhoto
        profileCompleted
        firstName
        lastName
        searchByName
        dob
        insuranceCarrier
        planID
        editStatus
        editRequested
        memberID
        streetAdress
        city
        state
        zip
        phone
        gender
        createdAt
        updatedAt
        owner
        __typename
      }
      accountStatus
      stripeCustomerID
      stripeSubscriptionID
      stripePriceID
      planID
      plan {
        id
        name
        planDetails
        createdAt
        updatedAt
        __typename
      }
      metadata
      updatedAt
      companyRegiEmployeeId
      employeeRegiUnSubscribeIDId
      owner
      __typename
    }
  }
`;
export const onUpdateEmployeeRegi = /* GraphQL */ `
  subscription OnUpdateEmployeeRegi(
    $filter: ModelSubscriptionEmployeeRegiFilterInput
    $owner: String
  ) {
    onUpdateEmployeeRegi(filter: $filter, owner: $owner) {
      id
      firstName
      lastName
      fcmToken
      searchByName
      employeeEmail
      emailType
      verified
      role
      userType
      emailID
      epcEmployerID
      createdAt
      inList
      lastLogin
      billing_status
      total_user
      members {
        nextToken
        __typename
      }
      dpcMembers {
        nextToken
        __typename
      }
      redeemedCoupon {
        promotionalID
        date
        __typename
      }
      promotionalID
      dpcDoctorID
      dpcDoctor {
        id
        physicianID
        doctorName
        searchByName
        byName
        doctorProfilePhoto
        doctorDegree
        doctorEmail
        aboutDoctor
        doctorEducation
        doctorOfficeAdd
        areaOfSpeciality
        bodyArea
        specialities
        doctorOfficeCity
        doctorOfficeState
        doctorOfficeZip
        doctorOfficePhone
        doctorOfficeFax
        doctorPracticeName
        doctorWebsite
        doctorFacility
        surgeryCenter
        googleRating
        facebookRating
        testimonial
        activeOnPortal
        distance
        specialty
        startDate
        isDpcDoctor
        createdAt
        updatedAt
        __typename
      }
      companyID {
        id
        approved
        name
        searchByName
        url
        phoneNumber
        email
        emailDomain
        planCategory
        profileID
        billing_status
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        createdAt
        updatedAt
        companyRegiUnSubscribeIDId
        owner
        __typename
      }
      unSubscribeID {
        id
        reason
        createdAt
        updatedAt
        unSubscribeEmployeeId
        unSubscribeMemberId
        unSubscribeCompanyId
        owner
        __typename
      }
      DpcBillingID
      DpcDetails {
        id
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        createdAt
        updatedAt
        __typename
      }
      epcCheckoutLink {
        checkoutID
        url
        expiry
        __typename
      }
      profile {
        email
        profilePhoto
        profileCompleted
        firstName
        lastName
        searchByName
        dob
        insuranceCarrier
        planID
        editStatus
        editRequested
        memberID
        streetAdress
        city
        state
        zip
        phone
        gender
        createdAt
        updatedAt
        owner
        __typename
      }
      accountStatus
      stripeCustomerID
      stripeSubscriptionID
      stripePriceID
      planID
      plan {
        id
        name
        planDetails
        createdAt
        updatedAt
        __typename
      }
      metadata
      updatedAt
      companyRegiEmployeeId
      employeeRegiUnSubscribeIDId
      owner
      __typename
    }
  }
`;
export const onDeleteEmployeeRegi = /* GraphQL */ `
  subscription OnDeleteEmployeeRegi(
    $filter: ModelSubscriptionEmployeeRegiFilterInput
    $owner: String
  ) {
    onDeleteEmployeeRegi(filter: $filter, owner: $owner) {
      id
      firstName
      lastName
      fcmToken
      searchByName
      employeeEmail
      emailType
      verified
      role
      userType
      emailID
      epcEmployerID
      createdAt
      inList
      lastLogin
      billing_status
      total_user
      members {
        nextToken
        __typename
      }
      dpcMembers {
        nextToken
        __typename
      }
      redeemedCoupon {
        promotionalID
        date
        __typename
      }
      promotionalID
      dpcDoctorID
      dpcDoctor {
        id
        physicianID
        doctorName
        searchByName
        byName
        doctorProfilePhoto
        doctorDegree
        doctorEmail
        aboutDoctor
        doctorEducation
        doctorOfficeAdd
        areaOfSpeciality
        bodyArea
        specialities
        doctorOfficeCity
        doctorOfficeState
        doctorOfficeZip
        doctorOfficePhone
        doctorOfficeFax
        doctorPracticeName
        doctorWebsite
        doctorFacility
        surgeryCenter
        googleRating
        facebookRating
        testimonial
        activeOnPortal
        distance
        specialty
        startDate
        isDpcDoctor
        createdAt
        updatedAt
        __typename
      }
      companyID {
        id
        approved
        name
        searchByName
        url
        phoneNumber
        email
        emailDomain
        planCategory
        profileID
        billing_status
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        createdAt
        updatedAt
        companyRegiUnSubscribeIDId
        owner
        __typename
      }
      unSubscribeID {
        id
        reason
        createdAt
        updatedAt
        unSubscribeEmployeeId
        unSubscribeMemberId
        unSubscribeCompanyId
        owner
        __typename
      }
      DpcBillingID
      DpcDetails {
        id
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        createdAt
        updatedAt
        __typename
      }
      epcCheckoutLink {
        checkoutID
        url
        expiry
        __typename
      }
      profile {
        email
        profilePhoto
        profileCompleted
        firstName
        lastName
        searchByName
        dob
        insuranceCarrier
        planID
        editStatus
        editRequested
        memberID
        streetAdress
        city
        state
        zip
        phone
        gender
        createdAt
        updatedAt
        owner
        __typename
      }
      accountStatus
      stripeCustomerID
      stripeSubscriptionID
      stripePriceID
      planID
      plan {
        id
        name
        planDetails
        createdAt
        updatedAt
        __typename
      }
      metadata
      updatedAt
      companyRegiEmployeeId
      employeeRegiUnSubscribeIDId
      owner
      __typename
    }
  }
`;
export const onCreateDpcDetails = /* GraphQL */ `
  subscription OnCreateDpcDetails(
    $filter: ModelSubscriptionDpcDetailsFilterInput
  ) {
    onCreateDpcDetails(filter: $filter) {
      id
      accountStatus
      stripeCustomerID
      stripeSubscriptionID
      stripePriceID
      planID
      plan {
        id
        name
        planDetails
        createdAt
        updatedAt
        __typename
      }
      metadata
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateDpcDetails = /* GraphQL */ `
  subscription OnUpdateDpcDetails(
    $filter: ModelSubscriptionDpcDetailsFilterInput
  ) {
    onUpdateDpcDetails(filter: $filter) {
      id
      accountStatus
      stripeCustomerID
      stripeSubscriptionID
      stripePriceID
      planID
      plan {
        id
        name
        planDetails
        createdAt
        updatedAt
        __typename
      }
      metadata
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteDpcDetails = /* GraphQL */ `
  subscription OnDeleteDpcDetails(
    $filter: ModelSubscriptionDpcDetailsFilterInput
  ) {
    onDeleteDpcDetails(filter: $filter) {
      id
      accountStatus
      stripeCustomerID
      stripeSubscriptionID
      stripePriceID
      planID
      plan {
        id
        name
        planDetails
        createdAt
        updatedAt
        __typename
      }
      metadata
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreatePlan = /* GraphQL */ `
  subscription OnCreatePlan($filter: ModelSubscriptionPlanFilterInput) {
    onCreatePlan(filter: $filter) {
      id
      name
      planDetails
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdatePlan = /* GraphQL */ `
  subscription OnUpdatePlan($filter: ModelSubscriptionPlanFilterInput) {
    onUpdatePlan(filter: $filter) {
      id
      name
      planDetails
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeletePlan = /* GraphQL */ `
  subscription OnDeletePlan($filter: ModelSubscriptionPlanFilterInput) {
    onDeletePlan(filter: $filter) {
      id
      name
      planDetails
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateEmployeeProfile = /* GraphQL */ `
  subscription OnCreateEmployeeProfile(
    $filter: ModelSubscriptionEmployeeProfileFilterInput
    $owner: String
  ) {
    onCreateEmployeeProfile(filter: $filter, owner: $owner) {
      email
      profilePhoto
      profileCompleted
      firstName
      lastName
      searchByName
      dob
      insuranceCarrier
      planID
      editStatus
      editRequested
      memberID
      firstVisit {
        reward
        procedure
        homePage
        firstVisit
        doctor
        surgery
        imaging
        infusion
        lab
        physicalTherapy
        __typename
      }
      streetAdress
      city
      state
      zip
      phone
      gender
      location {
        lat
        lon
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateEmployeeProfile = /* GraphQL */ `
  subscription OnUpdateEmployeeProfile(
    $filter: ModelSubscriptionEmployeeProfileFilterInput
    $owner: String
  ) {
    onUpdateEmployeeProfile(filter: $filter, owner: $owner) {
      email
      profilePhoto
      profileCompleted
      firstName
      lastName
      searchByName
      dob
      insuranceCarrier
      planID
      editStatus
      editRequested
      memberID
      firstVisit {
        reward
        procedure
        homePage
        firstVisit
        doctor
        surgery
        imaging
        infusion
        lab
        physicalTherapy
        __typename
      }
      streetAdress
      city
      state
      zip
      phone
      gender
      location {
        lat
        lon
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteEmployeeProfile = /* GraphQL */ `
  subscription OnDeleteEmployeeProfile(
    $filter: ModelSubscriptionEmployeeProfileFilterInput
    $owner: String
  ) {
    onDeleteEmployeeProfile(filter: $filter, owner: $owner) {
      email
      profilePhoto
      profileCompleted
      firstName
      lastName
      searchByName
      dob
      insuranceCarrier
      planID
      editStatus
      editRequested
      memberID
      firstVisit {
        reward
        procedure
        homePage
        firstVisit
        doctor
        surgery
        imaging
        infusion
        lab
        physicalTherapy
        __typename
      }
      streetAdress
      city
      state
      zip
      phone
      gender
      location {
        lat
        lon
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateMember = /* GraphQL */ `
  subscription OnCreateMember(
    $filter: ModelSubscriptionMemberFilterInput
    $owner: String
  ) {
    onCreateMember(filter: $filter, owner: $owner) {
      id
      firstName
      lastName
      fcmToken
      searchByName
      lastLogin
      relation
      group
      email
      emailType
      verified
      role
      userType
      inList
      emailID
      DpcBillingID
      DpcDetails {
        id
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        createdAt
        updatedAt
        __typename
      }
      dpcEmployeeID
      dpcEmployee {
        id
        firstName
        lastName
        fcmToken
        searchByName
        employeeEmail
        emailType
        verified
        role
        userType
        emailID
        epcEmployerID
        createdAt
        inList
        lastLogin
        billing_status
        total_user
        promotionalID
        dpcDoctorID
        DpcBillingID
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        updatedAt
        companyRegiEmployeeId
        employeeRegiUnSubscribeIDId
        owner
        __typename
      }
      dpcDoctorID
      dpcDoctor {
        id
        physicianID
        doctorName
        searchByName
        byName
        doctorProfilePhoto
        doctorDegree
        doctorEmail
        aboutDoctor
        doctorEducation
        doctorOfficeAdd
        areaOfSpeciality
        bodyArea
        specialities
        doctorOfficeCity
        doctorOfficeState
        doctorOfficeZip
        doctorOfficePhone
        doctorOfficeFax
        doctorPracticeName
        doctorWebsite
        doctorFacility
        surgeryCenter
        googleRating
        facebookRating
        testimonial
        activeOnPortal
        distance
        specialty
        startDate
        isDpcDoctor
        createdAt
        updatedAt
        __typename
      }
      companyID {
        id
        approved
        name
        searchByName
        url
        phoneNumber
        email
        emailDomain
        planCategory
        profileID
        billing_status
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        createdAt
        updatedAt
        companyRegiUnSubscribeIDId
        owner
        __typename
      }
      employeeID {
        id
        firstName
        lastName
        fcmToken
        searchByName
        employeeEmail
        emailType
        verified
        role
        userType
        emailID
        epcEmployerID
        createdAt
        inList
        lastLogin
        billing_status
        total_user
        promotionalID
        dpcDoctorID
        DpcBillingID
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        updatedAt
        companyRegiEmployeeId
        employeeRegiUnSubscribeIDId
        owner
        __typename
      }
      unSubscribeID {
        id
        reason
        createdAt
        updatedAt
        unSubscribeEmployeeId
        unSubscribeMemberId
        unSubscribeCompanyId
        owner
        __typename
      }
      profile {
        email
        profilePhoto
        profileCompleted
        firstName
        lastName
        searchByName
        editStatus
        editRequested
        gender
        dob
        insuranceCarrier
        planID
        memberID
        streetAdress
        city
        state
        zip
        phone
        createdAt
        updatedAt
        owner
        __typename
      }
      billing_status
      accountStatus
      stripeSubscriptionID
      createdAt
      updatedAt
      employeeRegiMembersId
      companyRegiMemberId
      memberUnSubscribeIDId
      owner
      __typename
    }
  }
`;
export const onUpdateMember = /* GraphQL */ `
  subscription OnUpdateMember(
    $filter: ModelSubscriptionMemberFilterInput
    $owner: String
  ) {
    onUpdateMember(filter: $filter, owner: $owner) {
      id
      firstName
      lastName
      fcmToken
      searchByName
      lastLogin
      relation
      group
      email
      emailType
      verified
      role
      userType
      inList
      emailID
      DpcBillingID
      DpcDetails {
        id
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        createdAt
        updatedAt
        __typename
      }
      dpcEmployeeID
      dpcEmployee {
        id
        firstName
        lastName
        fcmToken
        searchByName
        employeeEmail
        emailType
        verified
        role
        userType
        emailID
        epcEmployerID
        createdAt
        inList
        lastLogin
        billing_status
        total_user
        promotionalID
        dpcDoctorID
        DpcBillingID
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        updatedAt
        companyRegiEmployeeId
        employeeRegiUnSubscribeIDId
        owner
        __typename
      }
      dpcDoctorID
      dpcDoctor {
        id
        physicianID
        doctorName
        searchByName
        byName
        doctorProfilePhoto
        doctorDegree
        doctorEmail
        aboutDoctor
        doctorEducation
        doctorOfficeAdd
        areaOfSpeciality
        bodyArea
        specialities
        doctorOfficeCity
        doctorOfficeState
        doctorOfficeZip
        doctorOfficePhone
        doctorOfficeFax
        doctorPracticeName
        doctorWebsite
        doctorFacility
        surgeryCenter
        googleRating
        facebookRating
        testimonial
        activeOnPortal
        distance
        specialty
        startDate
        isDpcDoctor
        createdAt
        updatedAt
        __typename
      }
      companyID {
        id
        approved
        name
        searchByName
        url
        phoneNumber
        email
        emailDomain
        planCategory
        profileID
        billing_status
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        createdAt
        updatedAt
        companyRegiUnSubscribeIDId
        owner
        __typename
      }
      employeeID {
        id
        firstName
        lastName
        fcmToken
        searchByName
        employeeEmail
        emailType
        verified
        role
        userType
        emailID
        epcEmployerID
        createdAt
        inList
        lastLogin
        billing_status
        total_user
        promotionalID
        dpcDoctorID
        DpcBillingID
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        updatedAt
        companyRegiEmployeeId
        employeeRegiUnSubscribeIDId
        owner
        __typename
      }
      unSubscribeID {
        id
        reason
        createdAt
        updatedAt
        unSubscribeEmployeeId
        unSubscribeMemberId
        unSubscribeCompanyId
        owner
        __typename
      }
      profile {
        email
        profilePhoto
        profileCompleted
        firstName
        lastName
        searchByName
        editStatus
        editRequested
        gender
        dob
        insuranceCarrier
        planID
        memberID
        streetAdress
        city
        state
        zip
        phone
        createdAt
        updatedAt
        owner
        __typename
      }
      billing_status
      accountStatus
      stripeSubscriptionID
      createdAt
      updatedAt
      employeeRegiMembersId
      companyRegiMemberId
      memberUnSubscribeIDId
      owner
      __typename
    }
  }
`;
export const onDeleteMember = /* GraphQL */ `
  subscription OnDeleteMember(
    $filter: ModelSubscriptionMemberFilterInput
    $owner: String
  ) {
    onDeleteMember(filter: $filter, owner: $owner) {
      id
      firstName
      lastName
      fcmToken
      searchByName
      lastLogin
      relation
      group
      email
      emailType
      verified
      role
      userType
      inList
      emailID
      DpcBillingID
      DpcDetails {
        id
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        createdAt
        updatedAt
        __typename
      }
      dpcEmployeeID
      dpcEmployee {
        id
        firstName
        lastName
        fcmToken
        searchByName
        employeeEmail
        emailType
        verified
        role
        userType
        emailID
        epcEmployerID
        createdAt
        inList
        lastLogin
        billing_status
        total_user
        promotionalID
        dpcDoctorID
        DpcBillingID
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        updatedAt
        companyRegiEmployeeId
        employeeRegiUnSubscribeIDId
        owner
        __typename
      }
      dpcDoctorID
      dpcDoctor {
        id
        physicianID
        doctorName
        searchByName
        byName
        doctorProfilePhoto
        doctorDegree
        doctorEmail
        aboutDoctor
        doctorEducation
        doctorOfficeAdd
        areaOfSpeciality
        bodyArea
        specialities
        doctorOfficeCity
        doctorOfficeState
        doctorOfficeZip
        doctorOfficePhone
        doctorOfficeFax
        doctorPracticeName
        doctorWebsite
        doctorFacility
        surgeryCenter
        googleRating
        facebookRating
        testimonial
        activeOnPortal
        distance
        specialty
        startDate
        isDpcDoctor
        createdAt
        updatedAt
        __typename
      }
      companyID {
        id
        approved
        name
        searchByName
        url
        phoneNumber
        email
        emailDomain
        planCategory
        profileID
        billing_status
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        createdAt
        updatedAt
        companyRegiUnSubscribeIDId
        owner
        __typename
      }
      employeeID {
        id
        firstName
        lastName
        fcmToken
        searchByName
        employeeEmail
        emailType
        verified
        role
        userType
        emailID
        epcEmployerID
        createdAt
        inList
        lastLogin
        billing_status
        total_user
        promotionalID
        dpcDoctorID
        DpcBillingID
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        updatedAt
        companyRegiEmployeeId
        employeeRegiUnSubscribeIDId
        owner
        __typename
      }
      unSubscribeID {
        id
        reason
        createdAt
        updatedAt
        unSubscribeEmployeeId
        unSubscribeMemberId
        unSubscribeCompanyId
        owner
        __typename
      }
      profile {
        email
        profilePhoto
        profileCompleted
        firstName
        lastName
        searchByName
        editStatus
        editRequested
        gender
        dob
        insuranceCarrier
        planID
        memberID
        streetAdress
        city
        state
        zip
        phone
        createdAt
        updatedAt
        owner
        __typename
      }
      billing_status
      accountStatus
      stripeSubscriptionID
      createdAt
      updatedAt
      employeeRegiMembersId
      companyRegiMemberId
      memberUnSubscribeIDId
      owner
      __typename
    }
  }
`;
export const onCreateMemberProfile = /* GraphQL */ `
  subscription OnCreateMemberProfile(
    $filter: ModelSubscriptionMemberProfileFilterInput
    $owner: String
  ) {
    onCreateMemberProfile(filter: $filter, owner: $owner) {
      email
      profilePhoto
      profileCompleted
      firstName
      lastName
      searchByName
      editStatus
      editRequested
      gender
      dob
      firstVisit {
        reward
        procedure
        homePage
        firstVisit
        doctor
        surgery
        imaging
        infusion
        lab
        physicalTherapy
        __typename
      }
      insuranceCarrier
      planID
      memberID
      streetAdress
      city
      state
      zip
      phone
      location {
        lat
        lon
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateMemberProfile = /* GraphQL */ `
  subscription OnUpdateMemberProfile(
    $filter: ModelSubscriptionMemberProfileFilterInput
    $owner: String
  ) {
    onUpdateMemberProfile(filter: $filter, owner: $owner) {
      email
      profilePhoto
      profileCompleted
      firstName
      lastName
      searchByName
      editStatus
      editRequested
      gender
      dob
      firstVisit {
        reward
        procedure
        homePage
        firstVisit
        doctor
        surgery
        imaging
        infusion
        lab
        physicalTherapy
        __typename
      }
      insuranceCarrier
      planID
      memberID
      streetAdress
      city
      state
      zip
      phone
      location {
        lat
        lon
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteMemberProfile = /* GraphQL */ `
  subscription OnDeleteMemberProfile(
    $filter: ModelSubscriptionMemberProfileFilterInput
    $owner: String
  ) {
    onDeleteMemberProfile(filter: $filter, owner: $owner) {
      email
      profilePhoto
      profileCompleted
      firstName
      lastName
      searchByName
      editStatus
      editRequested
      gender
      dob
      firstVisit {
        reward
        procedure
        homePage
        firstVisit
        doctor
        surgery
        imaging
        infusion
        lab
        physicalTherapy
        __typename
      }
      insuranceCarrier
      planID
      memberID
      streetAdress
      city
      state
      zip
      phone
      location {
        lat
        lon
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateCompanyRegi = /* GraphQL */ `
  subscription OnCreateCompanyRegi(
    $filter: ModelSubscriptionCompanyRegiFilterInput
    $owner: String
  ) {
    onCreateCompanyRegi(filter: $filter, owner: $owner) {
      id
      approved
      name
      searchByName
      url
      phoneNumber
      email
      emailDomain
      planCategory
      customPlanDetails {
        price
        maxEmployess
        name
        __typename
      }
      profileID
      profile {
        email
        name
        url
        phoneNumber
        benefitInfoCall
        adminDetails
        companyID
        streetAddress
        zip
        city
        state
        benefitDocs
        visionDocs
        otherDocs
        createdAt
        updatedAt
        owner
        __typename
      }
      unSubscribeID {
        id
        reason
        createdAt
        updatedAt
        unSubscribeEmployeeId
        unSubscribeMemberId
        unSubscribeCompanyId
        owner
        __typename
      }
      employee {
        nextToken
        __typename
      }
      member {
        nextToken
        __typename
      }
      billing_status
      accountStatus
      stripeCustomerID
      stripeSubscriptionID
      stripePriceID
      planID
      plan {
        id
        name
        planDetails
        createdAt
        updatedAt
        __typename
      }
      metadata
      createdAt
      updatedAt
      companyRegiUnSubscribeIDId
      owner
      __typename
    }
  }
`;
export const onUpdateCompanyRegi = /* GraphQL */ `
  subscription OnUpdateCompanyRegi(
    $filter: ModelSubscriptionCompanyRegiFilterInput
    $owner: String
  ) {
    onUpdateCompanyRegi(filter: $filter, owner: $owner) {
      id
      approved
      name
      searchByName
      url
      phoneNumber
      email
      emailDomain
      planCategory
      customPlanDetails {
        price
        maxEmployess
        name
        __typename
      }
      profileID
      profile {
        email
        name
        url
        phoneNumber
        benefitInfoCall
        adminDetails
        companyID
        streetAddress
        zip
        city
        state
        benefitDocs
        visionDocs
        otherDocs
        createdAt
        updatedAt
        owner
        __typename
      }
      unSubscribeID {
        id
        reason
        createdAt
        updatedAt
        unSubscribeEmployeeId
        unSubscribeMemberId
        unSubscribeCompanyId
        owner
        __typename
      }
      employee {
        nextToken
        __typename
      }
      member {
        nextToken
        __typename
      }
      billing_status
      accountStatus
      stripeCustomerID
      stripeSubscriptionID
      stripePriceID
      planID
      plan {
        id
        name
        planDetails
        createdAt
        updatedAt
        __typename
      }
      metadata
      createdAt
      updatedAt
      companyRegiUnSubscribeIDId
      owner
      __typename
    }
  }
`;
export const onDeleteCompanyRegi = /* GraphQL */ `
  subscription OnDeleteCompanyRegi(
    $filter: ModelSubscriptionCompanyRegiFilterInput
    $owner: String
  ) {
    onDeleteCompanyRegi(filter: $filter, owner: $owner) {
      id
      approved
      name
      searchByName
      url
      phoneNumber
      email
      emailDomain
      planCategory
      customPlanDetails {
        price
        maxEmployess
        name
        __typename
      }
      profileID
      profile {
        email
        name
        url
        phoneNumber
        benefitInfoCall
        adminDetails
        companyID
        streetAddress
        zip
        city
        state
        benefitDocs
        visionDocs
        otherDocs
        createdAt
        updatedAt
        owner
        __typename
      }
      unSubscribeID {
        id
        reason
        createdAt
        updatedAt
        unSubscribeEmployeeId
        unSubscribeMemberId
        unSubscribeCompanyId
        owner
        __typename
      }
      employee {
        nextToken
        __typename
      }
      member {
        nextToken
        __typename
      }
      billing_status
      accountStatus
      stripeCustomerID
      stripeSubscriptionID
      stripePriceID
      planID
      plan {
        id
        name
        planDetails
        createdAt
        updatedAt
        __typename
      }
      metadata
      createdAt
      updatedAt
      companyRegiUnSubscribeIDId
      owner
      __typename
    }
  }
`;
export const onCreateUnSubscribe = /* GraphQL */ `
  subscription OnCreateUnSubscribe(
    $filter: ModelSubscriptionUnSubscribeFilterInput
    $owner: String
  ) {
    onCreateUnSubscribe(filter: $filter, owner: $owner) {
      id
      reason
      employee {
        id
        firstName
        lastName
        fcmToken
        searchByName
        employeeEmail
        emailType
        verified
        role
        userType
        emailID
        epcEmployerID
        createdAt
        inList
        lastLogin
        billing_status
        total_user
        promotionalID
        dpcDoctorID
        DpcBillingID
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        updatedAt
        companyRegiEmployeeId
        employeeRegiUnSubscribeIDId
        owner
        __typename
      }
      member {
        id
        firstName
        lastName
        fcmToken
        searchByName
        lastLogin
        relation
        group
        email
        emailType
        verified
        role
        userType
        inList
        emailID
        DpcBillingID
        dpcEmployeeID
        dpcDoctorID
        billing_status
        accountStatus
        stripeSubscriptionID
        createdAt
        updatedAt
        employeeRegiMembersId
        companyRegiMemberId
        memberUnSubscribeIDId
        owner
        __typename
      }
      company {
        id
        approved
        name
        searchByName
        url
        phoneNumber
        email
        emailDomain
        planCategory
        profileID
        billing_status
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        createdAt
        updatedAt
        companyRegiUnSubscribeIDId
        owner
        __typename
      }
      createdAt
      updatedAt
      unSubscribeEmployeeId
      unSubscribeMemberId
      unSubscribeCompanyId
      owner
      __typename
    }
  }
`;
export const onUpdateUnSubscribe = /* GraphQL */ `
  subscription OnUpdateUnSubscribe(
    $filter: ModelSubscriptionUnSubscribeFilterInput
    $owner: String
  ) {
    onUpdateUnSubscribe(filter: $filter, owner: $owner) {
      id
      reason
      employee {
        id
        firstName
        lastName
        fcmToken
        searchByName
        employeeEmail
        emailType
        verified
        role
        userType
        emailID
        epcEmployerID
        createdAt
        inList
        lastLogin
        billing_status
        total_user
        promotionalID
        dpcDoctorID
        DpcBillingID
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        updatedAt
        companyRegiEmployeeId
        employeeRegiUnSubscribeIDId
        owner
        __typename
      }
      member {
        id
        firstName
        lastName
        fcmToken
        searchByName
        lastLogin
        relation
        group
        email
        emailType
        verified
        role
        userType
        inList
        emailID
        DpcBillingID
        dpcEmployeeID
        dpcDoctorID
        billing_status
        accountStatus
        stripeSubscriptionID
        createdAt
        updatedAt
        employeeRegiMembersId
        companyRegiMemberId
        memberUnSubscribeIDId
        owner
        __typename
      }
      company {
        id
        approved
        name
        searchByName
        url
        phoneNumber
        email
        emailDomain
        planCategory
        profileID
        billing_status
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        createdAt
        updatedAt
        companyRegiUnSubscribeIDId
        owner
        __typename
      }
      createdAt
      updatedAt
      unSubscribeEmployeeId
      unSubscribeMemberId
      unSubscribeCompanyId
      owner
      __typename
    }
  }
`;
export const onDeleteUnSubscribe = /* GraphQL */ `
  subscription OnDeleteUnSubscribe(
    $filter: ModelSubscriptionUnSubscribeFilterInput
    $owner: String
  ) {
    onDeleteUnSubscribe(filter: $filter, owner: $owner) {
      id
      reason
      employee {
        id
        firstName
        lastName
        fcmToken
        searchByName
        employeeEmail
        emailType
        verified
        role
        userType
        emailID
        epcEmployerID
        createdAt
        inList
        lastLogin
        billing_status
        total_user
        promotionalID
        dpcDoctorID
        DpcBillingID
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        updatedAt
        companyRegiEmployeeId
        employeeRegiUnSubscribeIDId
        owner
        __typename
      }
      member {
        id
        firstName
        lastName
        fcmToken
        searchByName
        lastLogin
        relation
        group
        email
        emailType
        verified
        role
        userType
        inList
        emailID
        DpcBillingID
        dpcEmployeeID
        dpcDoctorID
        billing_status
        accountStatus
        stripeSubscriptionID
        createdAt
        updatedAt
        employeeRegiMembersId
        companyRegiMemberId
        memberUnSubscribeIDId
        owner
        __typename
      }
      company {
        id
        approved
        name
        searchByName
        url
        phoneNumber
        email
        emailDomain
        planCategory
        profileID
        billing_status
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        createdAt
        updatedAt
        companyRegiUnSubscribeIDId
        owner
        __typename
      }
      createdAt
      updatedAt
      unSubscribeEmployeeId
      unSubscribeMemberId
      unSubscribeCompanyId
      owner
      __typename
    }
  }
`;
export const onCreateCsvFileData = /* GraphQL */ `
  subscription OnCreateCsvFileData(
    $filter: ModelSubscriptionCsvFileDataFilterInput
  ) {
    onCreateCsvFileData(filter: $filter) {
      payer
      produt
      fileyear
      fileMonth
      groupCode
      cvgLevel
      memberSSN
      memberID
      memberSfx
      memberIDSeq
      memberRelCode
      memberGender
      memberDOB
      memberAddress1
      memberAddress2
      memberCity
      memberState
      memberZip
      memberCountry
      memberMedCvg
      subscrLastName
      subscrFirstName
      subscrMI
      memberLastName
      memberFirstName
      memberMI
      medEffDt
      medTermDt
      medStatus
      deptCode
      medBenPlanCode
      rxBenPlanCode
      cobFlag
      pcpTaxID
      pcpFirstName
      pcpLastName
      pcpAddressLine1
      pcpAddressLine2
      pcpCity
      pcpState
      pcpZipCode
      medicareCovSel
      lasered
      laseredAmount
      GroupName
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCsvFileData = /* GraphQL */ `
  subscription OnUpdateCsvFileData(
    $filter: ModelSubscriptionCsvFileDataFilterInput
  ) {
    onUpdateCsvFileData(filter: $filter) {
      payer
      produt
      fileyear
      fileMonth
      groupCode
      cvgLevel
      memberSSN
      memberID
      memberSfx
      memberIDSeq
      memberRelCode
      memberGender
      memberDOB
      memberAddress1
      memberAddress2
      memberCity
      memberState
      memberZip
      memberCountry
      memberMedCvg
      subscrLastName
      subscrFirstName
      subscrMI
      memberLastName
      memberFirstName
      memberMI
      medEffDt
      medTermDt
      medStatus
      deptCode
      medBenPlanCode
      rxBenPlanCode
      cobFlag
      pcpTaxID
      pcpFirstName
      pcpLastName
      pcpAddressLine1
      pcpAddressLine2
      pcpCity
      pcpState
      pcpZipCode
      medicareCovSel
      lasered
      laseredAmount
      GroupName
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCsvFileData = /* GraphQL */ `
  subscription OnDeleteCsvFileData(
    $filter: ModelSubscriptionCsvFileDataFilterInput
  ) {
    onDeleteCsvFileData(filter: $filter) {
      payer
      produt
      fileyear
      fileMonth
      groupCode
      cvgLevel
      memberSSN
      memberID
      memberSfx
      memberIDSeq
      memberRelCode
      memberGender
      memberDOB
      memberAddress1
      memberAddress2
      memberCity
      memberState
      memberZip
      memberCountry
      memberMedCvg
      subscrLastName
      subscrFirstName
      subscrMI
      memberLastName
      memberFirstName
      memberMI
      medEffDt
      medTermDt
      medStatus
      deptCode
      medBenPlanCode
      rxBenPlanCode
      cobFlag
      pcpTaxID
      pcpFirstName
      pcpLastName
      pcpAddressLine1
      pcpAddressLine2
      pcpCity
      pcpState
      pcpZipCode
      medicareCovSel
      lasered
      laseredAmount
      GroupName
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateSelfInsuredEmp = /* GraphQL */ `
  subscription OnCreateSelfInsuredEmp(
    $filter: ModelSubscriptionSelfInsuredEmpFilterInput
    $owner: String
  ) {
    onCreateSelfInsuredEmp(filter: $filter, owner: $owner) {
      email
      firstName
      lastName
      companyName
      companyID
      registered
      searchByName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateSelfInsuredEmp = /* GraphQL */ `
  subscription OnUpdateSelfInsuredEmp(
    $filter: ModelSubscriptionSelfInsuredEmpFilterInput
    $owner: String
  ) {
    onUpdateSelfInsuredEmp(filter: $filter, owner: $owner) {
      email
      firstName
      lastName
      companyName
      companyID
      registered
      searchByName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteSelfInsuredEmp = /* GraphQL */ `
  subscription OnDeleteSelfInsuredEmp(
    $filter: ModelSubscriptionSelfInsuredEmpFilterInput
    $owner: String
  ) {
    onDeleteSelfInsuredEmp(filter: $filter, owner: $owner) {
      email
      firstName
      lastName
      companyName
      companyID
      registered
      searchByName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreatePromotionalPartner = /* GraphQL */ `
  subscription OnCreatePromotionalPartner(
    $filter: ModelSubscriptionPromotionalPartnerFilterInput
  ) {
    onCreatePromotionalPartner(filter: $filter) {
      id
      name
      email
      phone
      url
      searchByName
      streetAddress
      city
      state
      zip
      promotionalCode
      referal {
        discountType
        discount
        planType
        couponID
        promotionalID
        promotionalCode
        active
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdatePromotionalPartner = /* GraphQL */ `
  subscription OnUpdatePromotionalPartner(
    $filter: ModelSubscriptionPromotionalPartnerFilterInput
  ) {
    onUpdatePromotionalPartner(filter: $filter) {
      id
      name
      email
      phone
      url
      searchByName
      streetAddress
      city
      state
      zip
      promotionalCode
      referal {
        discountType
        discount
        planType
        couponID
        promotionalID
        promotionalCode
        active
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeletePromotionalPartner = /* GraphQL */ `
  subscription OnDeletePromotionalPartner(
    $filter: ModelSubscriptionPromotionalPartnerFilterInput
  ) {
    onDeletePromotionalPartner(filter: $filter) {
      id
      name
      email
      phone
      url
      searchByName
      streetAddress
      city
      state
      zip
      promotionalCode
      referal {
        discountType
        discount
        planType
        couponID
        promotionalID
        promotionalCode
        active
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateEligibleMembers = /* GraphQL */ `
  subscription OnCreateEligibleMembers(
    $filter: ModelSubscriptionEligibleMembersFilterInput
  ) {
    onCreateEligibleMembers(filter: $filter) {
      payer
      lastSend
      product
      fileYear
      fileMonth
      groupCode
      cvgLevel
      memberSSN
      memberID
      memberSfx
      memberIDSeq
      memberRelCode
      memberGender
      memberDOB
      memberAddress1
      memberAddress2
      memberCity
      memberState
      memberZip
      memberCounty
      memberMedCvg
      subscrLastName
      subscrFirstName
      subscrMI
      memberLastName
      memberFirstName
      memberMI
      medEffDt
      medTermDt
      medStatus
      deptCode
      medBenPlanCode
      rxBenPlanCode
      cobFlag
      pcpTaxID
      pcpFirstName
      pcpLastName
      pcpAddressLine1
      pcpAddressLine2
      pcpCity
      pcpState
      pcpZipCode
      medicareCovSel
      lasered
      laseredAmount
      GroupName
      registered
      invited
      companyID
      regiID
      email
      phone
      searchByName
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateEligibleMembers = /* GraphQL */ `
  subscription OnUpdateEligibleMembers(
    $filter: ModelSubscriptionEligibleMembersFilterInput
  ) {
    onUpdateEligibleMembers(filter: $filter) {
      payer
      lastSend
      product
      fileYear
      fileMonth
      groupCode
      cvgLevel
      memberSSN
      memberID
      memberSfx
      memberIDSeq
      memberRelCode
      memberGender
      memberDOB
      memberAddress1
      memberAddress2
      memberCity
      memberState
      memberZip
      memberCounty
      memberMedCvg
      subscrLastName
      subscrFirstName
      subscrMI
      memberLastName
      memberFirstName
      memberMI
      medEffDt
      medTermDt
      medStatus
      deptCode
      medBenPlanCode
      rxBenPlanCode
      cobFlag
      pcpTaxID
      pcpFirstName
      pcpLastName
      pcpAddressLine1
      pcpAddressLine2
      pcpCity
      pcpState
      pcpZipCode
      medicareCovSel
      lasered
      laseredAmount
      GroupName
      registered
      invited
      companyID
      regiID
      email
      phone
      searchByName
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteEligibleMembers = /* GraphQL */ `
  subscription OnDeleteEligibleMembers(
    $filter: ModelSubscriptionEligibleMembersFilterInput
  ) {
    onDeleteEligibleMembers(filter: $filter) {
      payer
      lastSend
      product
      fileYear
      fileMonth
      groupCode
      cvgLevel
      memberSSN
      memberID
      memberSfx
      memberIDSeq
      memberRelCode
      memberGender
      memberDOB
      memberAddress1
      memberAddress2
      memberCity
      memberState
      memberZip
      memberCounty
      memberMedCvg
      subscrLastName
      subscrFirstName
      subscrMI
      memberLastName
      memberFirstName
      memberMI
      medEffDt
      medTermDt
      medStatus
      deptCode
      medBenPlanCode
      rxBenPlanCode
      cobFlag
      pcpTaxID
      pcpFirstName
      pcpLastName
      pcpAddressLine1
      pcpAddressLine2
      pcpCity
      pcpState
      pcpZipCode
      medicareCovSel
      lasered
      laseredAmount
      GroupName
      registered
      invited
      companyID
      regiID
      email
      phone
      searchByName
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateSelfServingUsers = /* GraphQL */ `
  subscription OnCreateSelfServingUsers(
    $filter: ModelSubscriptionSelfServingUsersFilterInput
  ) {
    onCreateSelfServingUsers(filter: $filter) {
      id
      firstName
      lastName
      dob
      email
      phoneNumber
      streetAddress
      city
      state
      zipCode
      insuranceCarrier
      reference
      question
      doctorID
      doctorDetail {
        id
        physicianID
        doctorName
        searchByName
        byName
        doctorProfilePhoto
        doctorDegree
        doctorEmail
        aboutDoctor
        doctorEducation
        doctorOfficeAdd
        areaOfSpeciality
        bodyArea
        specialities
        doctorOfficeCity
        doctorOfficeState
        doctorOfficeZip
        doctorOfficePhone
        doctorOfficeFax
        doctorPracticeName
        doctorWebsite
        doctorFacility
        surgeryCenter
        googleRating
        facebookRating
        testimonial
        activeOnPortal
        distance
        specialty
        startDate
        isDpcDoctor
        createdAt
        updatedAt
        __typename
      }
      startDate
      isDependent
      uuid
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateSelfServingUsers = /* GraphQL */ `
  subscription OnUpdateSelfServingUsers(
    $filter: ModelSubscriptionSelfServingUsersFilterInput
  ) {
    onUpdateSelfServingUsers(filter: $filter) {
      id
      firstName
      lastName
      dob
      email
      phoneNumber
      streetAddress
      city
      state
      zipCode
      insuranceCarrier
      reference
      question
      doctorID
      doctorDetail {
        id
        physicianID
        doctorName
        searchByName
        byName
        doctorProfilePhoto
        doctorDegree
        doctorEmail
        aboutDoctor
        doctorEducation
        doctorOfficeAdd
        areaOfSpeciality
        bodyArea
        specialities
        doctorOfficeCity
        doctorOfficeState
        doctorOfficeZip
        doctorOfficePhone
        doctorOfficeFax
        doctorPracticeName
        doctorWebsite
        doctorFacility
        surgeryCenter
        googleRating
        facebookRating
        testimonial
        activeOnPortal
        distance
        specialty
        startDate
        isDpcDoctor
        createdAt
        updatedAt
        __typename
      }
      startDate
      isDependent
      uuid
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteSelfServingUsers = /* GraphQL */ `
  subscription OnDeleteSelfServingUsers(
    $filter: ModelSubscriptionSelfServingUsersFilterInput
  ) {
    onDeleteSelfServingUsers(filter: $filter) {
      id
      firstName
      lastName
      dob
      email
      phoneNumber
      streetAddress
      city
      state
      zipCode
      insuranceCarrier
      reference
      question
      doctorID
      doctorDetail {
        id
        physicianID
        doctorName
        searchByName
        byName
        doctorProfilePhoto
        doctorDegree
        doctorEmail
        aboutDoctor
        doctorEducation
        doctorOfficeAdd
        areaOfSpeciality
        bodyArea
        specialities
        doctorOfficeCity
        doctorOfficeState
        doctorOfficeZip
        doctorOfficePhone
        doctorOfficeFax
        doctorPracticeName
        doctorWebsite
        doctorFacility
        surgeryCenter
        googleRating
        facebookRating
        testimonial
        activeOnPortal
        distance
        specialty
        startDate
        isDpcDoctor
        createdAt
        updatedAt
        __typename
      }
      startDate
      isDependent
      uuid
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage($filter: ModelSubscriptionMessageFilterInput) {
    onCreateMessage(filter: $filter) {
      id
      content
      senderID
      systemContent
      senderDetails {
        id
        firstName
        lastName
        fcmToken
        searchByName
        employeeEmail
        emailType
        verified
        role
        userType
        emailID
        epcEmployerID
        createdAt
        inList
        lastLogin
        billing_status
        total_user
        promotionalID
        dpcDoctorID
        DpcBillingID
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        updatedAt
        companyRegiEmployeeId
        employeeRegiUnSubscribeIDId
        owner
        __typename
      }
      chatId
      chat {
        id
        senderID
        admin
        status
        createdAt
        feedback
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage($filter: ModelSubscriptionMessageFilterInput) {
    onUpdateMessage(filter: $filter) {
      id
      content
      senderID
      systemContent
      senderDetails {
        id
        firstName
        lastName
        fcmToken
        searchByName
        employeeEmail
        emailType
        verified
        role
        userType
        emailID
        epcEmployerID
        createdAt
        inList
        lastLogin
        billing_status
        total_user
        promotionalID
        dpcDoctorID
        DpcBillingID
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        updatedAt
        companyRegiEmployeeId
        employeeRegiUnSubscribeIDId
        owner
        __typename
      }
      chatId
      chat {
        id
        senderID
        admin
        status
        createdAt
        feedback
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteMessage = /* GraphQL */ `
  subscription OnDeleteMessage($filter: ModelSubscriptionMessageFilterInput) {
    onDeleteMessage(filter: $filter) {
      id
      content
      senderID
      systemContent
      senderDetails {
        id
        firstName
        lastName
        fcmToken
        searchByName
        employeeEmail
        emailType
        verified
        role
        userType
        emailID
        epcEmployerID
        createdAt
        inList
        lastLogin
        billing_status
        total_user
        promotionalID
        dpcDoctorID
        DpcBillingID
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        updatedAt
        companyRegiEmployeeId
        employeeRegiUnSubscribeIDId
        owner
        __typename
      }
      chatId
      chat {
        id
        senderID
        admin
        status
        createdAt
        feedback
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateChat = /* GraphQL */ `
  subscription OnCreateChat($filter: ModelSubscriptionChatFilterInput) {
    onCreateChat(filter: $filter) {
      id
      senderID
      senderDetails {
        id
        firstName
        lastName
        fcmToken
        searchByName
        employeeEmail
        emailType
        verified
        role
        userType
        emailID
        epcEmployerID
        createdAt
        inList
        lastLogin
        billing_status
        total_user
        promotionalID
        dpcDoctorID
        DpcBillingID
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        updatedAt
        companyRegiEmployeeId
        employeeRegiUnSubscribeIDId
        owner
        __typename
      }
      admin
      adminDetails {
        id
        firstName
        lastName
        fcmToken
        searchByName
        employeeEmail
        emailType
        verified
        role
        userType
        emailID
        epcEmployerID
        createdAt
        inList
        lastLogin
        billing_status
        total_user
        promotionalID
        dpcDoctorID
        DpcBillingID
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        updatedAt
        companyRegiEmployeeId
        employeeRegiUnSubscribeIDId
        owner
        __typename
      }
      messages {
        nextToken
        __typename
      }
      status
      createdAt
      feedback
      updatedAt
      __typename
    }
  }
`;
export const onUpdateChat = /* GraphQL */ `
  subscription OnUpdateChat($filter: ModelSubscriptionChatFilterInput) {
    onUpdateChat(filter: $filter) {
      id
      senderID
      senderDetails {
        id
        firstName
        lastName
        fcmToken
        searchByName
        employeeEmail
        emailType
        verified
        role
        userType
        emailID
        epcEmployerID
        createdAt
        inList
        lastLogin
        billing_status
        total_user
        promotionalID
        dpcDoctorID
        DpcBillingID
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        updatedAt
        companyRegiEmployeeId
        employeeRegiUnSubscribeIDId
        owner
        __typename
      }
      admin
      adminDetails {
        id
        firstName
        lastName
        fcmToken
        searchByName
        employeeEmail
        emailType
        verified
        role
        userType
        emailID
        epcEmployerID
        createdAt
        inList
        lastLogin
        billing_status
        total_user
        promotionalID
        dpcDoctorID
        DpcBillingID
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        updatedAt
        companyRegiEmployeeId
        employeeRegiUnSubscribeIDId
        owner
        __typename
      }
      messages {
        nextToken
        __typename
      }
      status
      createdAt
      feedback
      updatedAt
      __typename
    }
  }
`;
export const onDeleteChat = /* GraphQL */ `
  subscription OnDeleteChat($filter: ModelSubscriptionChatFilterInput) {
    onDeleteChat(filter: $filter) {
      id
      senderID
      senderDetails {
        id
        firstName
        lastName
        fcmToken
        searchByName
        employeeEmail
        emailType
        verified
        role
        userType
        emailID
        epcEmployerID
        createdAt
        inList
        lastLogin
        billing_status
        total_user
        promotionalID
        dpcDoctorID
        DpcBillingID
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        updatedAt
        companyRegiEmployeeId
        employeeRegiUnSubscribeIDId
        owner
        __typename
      }
      admin
      adminDetails {
        id
        firstName
        lastName
        fcmToken
        searchByName
        employeeEmail
        emailType
        verified
        role
        userType
        emailID
        epcEmployerID
        createdAt
        inList
        lastLogin
        billing_status
        total_user
        promotionalID
        dpcDoctorID
        DpcBillingID
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        updatedAt
        companyRegiEmployeeId
        employeeRegiUnSubscribeIDId
        owner
        __typename
      }
      messages {
        nextToken
        __typename
      }
      status
      createdAt
      feedback
      updatedAt
      __typename
    }
  }
`;
export const onCreateEpcEmployer = /* GraphQL */ `
  subscription OnCreateEpcEmployer(
    $filter: ModelSubscriptionEpcEmployerFilterInput
    $email: String
  ) {
    onCreateEpcEmployer(filter: $filter, email: $email) {
      id
      email
      searchByName
      byName
      profileId
      createdAt
      profile {
        id
        name
        email
        website
        phone
        streetAddress
        zip
        city
        state
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const onUpdateEpcEmployer = /* GraphQL */ `
  subscription OnUpdateEpcEmployer(
    $filter: ModelSubscriptionEpcEmployerFilterInput
    $email: String
  ) {
    onUpdateEpcEmployer(filter: $filter, email: $email) {
      id
      email
      searchByName
      byName
      profileId
      createdAt
      profile {
        id
        name
        email
        website
        phone
        streetAddress
        zip
        city
        state
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const onDeleteEpcEmployer = /* GraphQL */ `
  subscription OnDeleteEpcEmployer(
    $filter: ModelSubscriptionEpcEmployerFilterInput
    $email: String
  ) {
    onDeleteEpcEmployer(filter: $filter, email: $email) {
      id
      email
      searchByName
      byName
      profileId
      createdAt
      profile {
        id
        name
        email
        website
        phone
        streetAddress
        zip
        city
        state
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
