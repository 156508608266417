export const listEpcReferences = /* GraphQL */ `
  query ListSelfServingUsers(
    $filter: ModelSelfServingUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSelfServingUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        reference
      }
      nextToken
      __typename
    }
  }
`;

export const listEpcEmployersByCreatedAt = /* GraphQL */ `
  query EmployerByCreatedAt(
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEpcEmployerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    employerByCreatedAt(
      byName: "Employer"
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        searchByName
        byName
        profileId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const employerByName = /* GraphQL */ `
  query EmployerByName(
    $searchByNameEmail: ModelEpcEmployerEmployerSearchByNameCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEpcEmployerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    employerByName(
      byName: "Employer"
      searchByNameEmail: $searchByNameEmail
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        searchByName
        name
        byName
        profileId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listEPCDoctors = /* GraphQL */ `
  query ListDoctorProfiles($nextToken: String) {
    listDoctorProfiles(
      filter: { isDpcDoctor: { eq: true } }
      limit: 99999
      nextToken: $nextToken
    ) {
      items {
        id
        physicianID
        doctorName
        searchByName
        doctorOfficePhone
        doctorWebsite
        byName
        dpcMembers(limit: 9999) {
          items {
            DpcBillingID
            DpcDetails {
              accountStatus
            }
          }
        }
        dpcUsers(limit: 9999) {
          items {
            DpcBillingID
            DpcDetails {
              accountStatus
            }
          }
        }
        doctorEmail
        startDate
        isDpcDoctor
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listMembersCustom = `
query MyQuery($filter: ModelMemberFilterInput, $limit: Int){
  listMembers(limit: $limit,filter: $filter) {
    items {
      DpcBillingID
      emailID
      group
      firstName
      lastName
      DpcDetails {
        accountStatus
      }
      id
      email
      firstName
      lastName
    }
  }
}`;
