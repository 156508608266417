import { Card, Layout, Row } from "antd";
import { Header } from "antd/lib/layout/layout";
import logo from "assets/delphy/delphy.svg";
import Container from "commons/Container";
import notFound from "assets/img/404.svg";
import { useLocation } from "react-router-dom";

export const NotFound = () => {
  const location = useLocation();
  console.log(location);
  const subTitle = location?.state?.message;
  return (
    <>
      <Layout className="layout navbar">
        <section className="header">
          <Container>
            <Header className="main-haeder header-menu">
              <img
                src={logo}
                alt="Bazar Logo"
                className="main-logo"
                style={{ margin: "10px" }}
              />
            </Header>
          </Container>
        </section>

        <Container>
          <Card
            className="my-2"
            style={{
              height: "80vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Row justify="center" gutter={20}>
              <div className="no-search">
                <div className="no-search-box">
                  <img src={notFound} alt="" style={{ width: "500px" }} />
                  <h6 style={{ margin: "10px" }}>Page not found!</h6>
                  <h4>
                    {subTitle || "We can't find what you are looking for..."}
                  </h4>
                </div>
              </div>
            </Row>
          </Card>
        </Container>
      </Layout>
    </>
  );
};
