import {
  Layout,
  PageHeader,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  DatePicker,
  message,
  Button,
  Card,
  Select,
} from "antd";

import logo from "assets/delphy/delphy.svg";
import "./EpcSelfServingOnBoarding.less";
import { Content } from "antd/lib/layout/layout";
import SkeletonBlock from "utility/SkeletonBlocks";
import { useEffect, useRef, useState } from "react";
import {
  cityPattern,
  emailPattern,
  formatNumberUS,
  isUuid,
  specialPatternOnboarding,
  streetAddressPattern,
  tenDigit,
  zipPattern,
} from "utility/constant";
import awsmobile from "aws-exports";
import moment from "moment";
import { API } from "aws-amplify";
import { useNavigate, useParams } from "react-router-dom";
import { getEpcEmployerName, listDoctorCustom } from "graphql/customQuery";
import no_entry from "assets/img/not_accepting_entries.png";

export const EpcEmployerOnBoarding = () => {
  const employerName = useRef(null);
  const { id: employerID } = useParams();
  const [skeletonLoader, setskeletonLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [doctorList, setDoctorList] = useState([]);
  const [date, setDate] = useState("");
  const [dateValidation, setDateValidation] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [formNotAvailable, setFormNotAvailable] = useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const disabledDate = (current) => {
    const todayMinus18Years = moment().subtract(18, "years").endOf("day");
    return (
      current &&
      (current.isAfter(moment()) || current.isAfter(todayMinus18Years))
    );
  };

  // Function to detect if the browser is Safari
  const isSafari = () => {
    return (
      navigator.userAgent.indexOf("Safari") !== -1 &&
      navigator.userAgent.indexOf("Chrome") === -1
    );
  };

  const onChangeDatepicker = (date, dateString) => {
    const eighteenYearsAgo = moment().subtract(18, "years");
    const formattedEighteenYearsAgo = eighteenYearsAgo.format("YYYY-MM-DD");
    setDateValidation(formattedEighteenYearsAgo);
    setDate(moment(dateString, "MM-DD-YYYY").format("YYYY-MM-DD"));
  };

  function compareDates(date1, date2) {
    // Convert the date strings into Date objects
    const [year1, month1, day1] = date1.split("-").map(Number);
    const [year2, month2, day2] = date2.split("-").map(Number);

    const dateObj1 = new Date(year1, month1 - 1, day1);
    const dateObj2 = new Date(year2, month2 - 1, day2);

    // Compare the two dates
    return dateObj1 < dateObj2;
  }

  async function fetchEmployer() {
    if (!employerID || !isUuid.test(employerID)) {
      navigate("/404");
    }
    setskeletonLoader(true);

    const [response, doctorResponse] = await Promise.all([
      API.graphql({
        query: getEpcEmployerName,
        variables: {
          id: employerID,
        },
        authMode: "API_KEY",
        authToken: awsmobile.aws_appsync_apiKey,
      }),
      API.graphql({
        query: listDoctorCustom,
        variables: {
          filter: { isDpcDoctor: { eq: true }, activeOnPortal: { eq: true } },
          limit: "9999",
        },
        authMode: "API_KEY",
        authToken: awsmobile.aws_appsync_apiKey,
      }),
    ]);

    setDoctorList(doctorResponse?.data?.listDoctorProfiles?.items);
    console.log(doctorResponse?.data?.listDoctorProfiles?.items);
    if (!response?.data?.getEpcEmployer) {
      navigate("/404", {
        state: { message: `No data found for Employer ${employerID}` },
      });
    }
    employerName.current =
      response?.data?.getEpcEmployer?.searchByName?.toUpperCase();
    if (response?.data?.getEpcEmployer?.profile?.form?.formStatus === "OFF") {
      setFormNotAvailable(true);
      document.body.style.overflow = "hidden";
    }

    setskeletonLoader(false);
  }

  useEffect(() => {
    fetchEmployer();
  }, []);

  const handleFormSubmit = async (values) => {
    try {
      //Code to handle form submit

      /* 1) Check if doctor is full or not. IF full turn off the doctor avability and show error and mail to client.
         2) Call the API
         3) Handle the API success and errors */

      setLoading(true);
      const doctorID =
        doctorList.find((item) => item.doctorName === values?.doctor)?.id ??
        null;

      const payloadData = {
        firstName: (values?.firstName).trim(),
        lastName: (values?.lastName).trim(),
        dob: date,
        email: values?.email,
        phone: values?.phone,
        streetAddress: values?.streetAddress,
        city: values?.city,
        state: values?.state,
        zip: values?.zip,
        gender: values?.gender,
        insuranceCarrier: (values?.insuranceCarrier).trim(),
        doctorID,
      };

      const myInit = {
        body: {
          payloadData,
        },
      };
      await API.post(
        "bazarhealthREST",
        `/selfServingOnboarding/employer/${employerID}`,
        myInit
      );

      navigate("/registration-success", {
        state: { employerName: employerName.current },
      });
    } catch (error) {
      console.log(error, "error");
      setLoading(false);

      messageApi.open({
        type: "error",
        content: error?.response?.data?.error || "Something went wrong!",
      });
    } finally {
      setLoading(false);
    }
  };

  const formNotAvailableModal = () => {
    return (
      <div
        style={{
          position: "fixed", // Ensure it's fixed relative to the viewport
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(0, 0, 0, 0.6)", // Semi-transparent background
          display: "flex",
          justifyContent: "center",
          alignItems: "center", // Center the content vertically and horizontally
          zIndex: 1000,
        }}
      >
        <Row justify="center" gutter={20}>
          <Card
            style={{
              padding: "20px",
              textAlign: "center",
              color: "#fff",
              backgroundColor: "rgba(255, 255, 255, 0.9)",
              borderRadius: "16px",
              boxShadow: "0 4px 20px rgba(0, 0, 0, 0.3)",
            }}
          >
            <div className="no-search-box">
              <img src={no_entry} alt="No Entry" style={{ width: "400px" }} />
              <h2 style={{ margin: "10px", color: "#000" }}>
                Not enrolling at this time
              </h2>
              <h3 style={{ color: "#333" }}>
                {"Contact delphihc.com or your employer's admin..."}
              </h3>
            </div>
          </Card>
        </Row>
      </div>
    );
  };

  return (
    <>
      {contextHolder}
      <Layout>
        <PageHeader style={{ backgroundColor: "#CEE1ED" }}>
          <Row className="onboarding-page" justify="center" align="middle">
            <Col span={24} className="responsive-mt-20">
              <img src={logo} alt="Bazar Logo" className="bazar-logo" />
            </Col>
          </Row>
          <Row
            className="onboarding-page"
            justify="center"
            align="middle"
            style={{ padding: "25px", color: "#00274C" }}
          >
            <h2
              style={{
                fontWeight: "700",
                fontSize: "30px",
                lineHeight: "24px",
                color: "#00274C",
              }}
            >
              {" "}
              {skeletonLoader ? (
                <SkeletonBlock style={{ width: "100%", height: 50 }} />
              ) : (
                <>
                  <span>Join Delphi Enhanced Primary Care Provided by</span>
                  <br />
                  <span
                    style={{
                      textAlign: "center",
                      display: "block",
                      margin: "10px auto",
                    }}
                  >
                    {" "}
                    {employerName.current || "Unnamed"}{" "}
                  </span>
                </>
              )}
            </h2>
          </Row>
        </PageHeader>
        <Content style={{ backgroundColor: "#F3F9FD" }}>
          <Row justify="center" className="update-form">
            <Col span={16} md={16} xs={24} sm={24} style={{ maxWidth: "55%" }}>
              {skeletonLoader ? (
                <>
                  <Row gutter={[20, 20]} className="mt-3">
                    <SkeletonBlock style={{ width: "100%", height: 50 }} />
                    <SkeletonBlock style={{ width: "100%", height: 50 }} />
                    <SkeletonBlock style={{ width: "100%", height: 50 }} />
                    <SkeletonBlock style={{ width: "100%", height: 50 }} />
                    <SkeletonBlock style={{ width: "100%", height: 50 }} />
                    <SkeletonBlock style={{ width: "100%", height: 50 }} />
                    <SkeletonBlock style={{ width: "100%", height: 200 }} />
                  </Row>
                </>
              ) : (
                <>
                  {formNotAvailable && formNotAvailableModal()}
                  <div
                    style={{
                      filter: formNotAvailable ? "blur(5px)" : "none", // Apply blur when `isBlurred` is true
                      pointerEvents: formNotAvailable ? "none" : "auto", // Disable interaction when blurred
                      transition: "formStatus 0.3s ease",
                    }}
                  >
                    <Form
                      name="basic"
                      form={form}
                      onFinish={handleFormSubmit}
                      autoComplete="off"
                      layout="vertical"
                      className="mt-20"
                      validateFirst={true}
                    >
                      <Row gutter={20}>
                        <Col span={12} md={12} xs={24} sm={24}>
                          <Form.Item
                            label="First Name"
                            name="firstName"
                            required
                            validateFirst={true}
                            rules={[
                              {
                                required: true,
                                message: "Please input your first name!",
                              },
                              {
                                pattern: /^[A-Za-z]*(?: [A-Za-z]+)*$/,
                                message: "Name can only contain alphabets",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={12} md={12} xs={24} sm={24}>
                          <Form.Item
                            label="Last Name"
                            name="lastName"
                            required
                            validateFirst={true}
                            rules={[
                              {
                                required: true,
                                message: "Please input your last name!",
                              },
                              {
                                pattern: /^[A-Za-z]*(?: [A-Za-z]+)*$/,
                                message: "Name can only contain alphabets",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={12} md={12} xs={24} sm={24}>
                          <Form.Item
                            label="Date of Birth - Must be 18 or older"
                            name="dob"
                            validateFirst={true}
                            rules={[
                              {
                                required: true,
                                message: "Please enter your DOB!",
                              },
                              ...(isSafari()
                                ? []
                                : [
                                    {
                                      validator: (_, value) => {
                                        if (
                                          compareDates(date, dateValidation)
                                        ) {
                                          return Promise.resolve();
                                        }
                                        return Promise.reject(
                                          new Error("Must be 18 or older!")
                                        );
                                      },
                                    },
                                  ]),
                            ]}
                          >
                            <DatePicker
                              onChange={onChangeDatepicker}
                              placeholder="MM-DD-YYYY"
                              format="MM-DD-YYYY"
                              disabledDate={disabledDate}
                              defaultPickerValue={moment()
                                .subtract(18, "years")
                                .startOf("day")}
                              style={{ width: "100%" }}
                              showToday={false}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12} md={12} xs={24} sm={24}>
                          <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                              {
                                required: true,
                                message: "Please input your email!",
                              },
                              {
                                pattern: emailPattern,
                                message: "Please enter a valid email",
                              },
                            ]}
                          >
                            <Input placeholder="email@email.com" />
                          </Form.Item>
                        </Col>

                        <Col span={12} md={12} xs={24} sm={24}>
                          <Form.Item
                            label="Cell Phone Number"
                            name="phone"
                            rules={[
                              {
                                required: true,
                                message: "Please input your phone number!",
                              },
                              {
                                pattern: tenDigit,
                                message: "Please enter a valid phone number",
                              },
                            ]}
                          >
                            <InputNumber
                              controls={false}
                              placeholder="(111) 111-1111"
                              formatter={(value) => formatNumberUS(value)}
                              parser={(value) => {
                                const cleanedValue = value?.replace(/\D/g, "");
                                return cleanedValue.slice(0, 10);
                              }}
                              style={{ width: "100%", borderRadius: "5px" }}
                              maxLength={14}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12} md={12} xs={24} sm={24}>
                          <Form.Item
                            label="Choose Your Medical Provider"
                            name="doctor"
                            rules={[
                              {
                                required: true,
                                message: "Please select any employer",
                              },
                            ]}
                          >
                            <Select
                              allowClear
                              placeholder="Select your provider"
                            >
                              {doctorList.map((item) => (
                                <Select.Option
                                  key={item?.id}
                                  value={item?.doctorName} //have start date too, once it is done.
                                >{`${item.doctorName}`}</Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={12} md={12} xs={24} sm={24}>
                          <Form.Item
                            label="Gender"
                            name="gender"
                            rules={[
                              {
                                required: true,
                                message: "Please select your gender",
                              },
                            ]}
                          >
                            <Select allowClear placeholder="Select your gender">
                              {["Male", "Female", "Others"].map((item) => (
                                <Select.Option
                                  key={item}
                                  value={item} //have start date too, once it is done.
                                >{`${item}`}</Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={12} md={12} xs={24} sm={24}>
                          <Form.Item
                            label="Street Address"
                            name="streetAddress"
                            rules={[
                              {
                                required: true,
                                message: "Please input your street address!",
                              },
                              {
                                pattern: streetAddressPattern,
                                message: "Please enter a valid street address",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col span={12} md={12} xs={24} sm={24}>
                          <Form.Item
                            label="City"
                            name="city"
                            rules={[
                              {
                                required: true,
                                message: "Please input your city!",
                              },
                              {
                                pattern: cityPattern,
                                message: "Please enter a valid city name",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={12} md={12} xs={24} sm={24}>
                          <Form.Item
                            label="State"
                            name="state"
                            rules={[
                              {
                                required: true,
                                message: "Please input your state!",
                              },
                              {
                                pattern: cityPattern,
                                message: "Please enter a valid state name",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={12} md={12} xs={24} sm={24}>
                          <Form.Item
                            label="Zip Code"
                            name="zip"
                            rules={[
                              {
                                required: true,
                                message: "Please input your zip code!",
                              },
                              {
                                pattern: zipPattern,
                                message: "Please enter a valid ZIP code",
                              },
                            ]}
                          >
                            <Input maxLength={5} />
                          </Form.Item>
                        </Col>
                        <Col span={12} md={12} xs={24} sm={24}>
                          <Form.Item
                            label="Insurance Carrier"
                            name="insuranceCarrier"
                            rules={[
                              {
                                required: true,
                                message: "Please input your insurance carrier!",
                              },
                              {
                                pattern: specialPatternOnboarding,
                                message:
                                  "Please enter a valid insurance carrier",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col
                          span={12}
                          md={24}
                          xs={24}
                          sm={24}
                          style={{ textAlign: "center" }}
                        >
                          <Button
                            size="large"
                            className="theme-btn"
                            htmlType="submit"
                            style={{ margin: "5px" }}
                            loading={loading}
                          >
                            Submit
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </>
              )}
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
};
