import {
  ExecuteCustom,
  ExecuteCustomQueries,
  ExecuteCustomQuery,
  ExecuteMutation,
  ExecutePublicAPI,
  ExecuteQuery,
  ExecuteQueryCustom,
} from "./api";

export const ListLabs = (limit, filter) => {
  return ExecuteQueryCustom(
    "listLabDetails",
    undefined,
    undefined,
    undefined,
    filter,
    undefined,
    undefined,
    limit
  );
};

export const ListPublicLabs = (limit, filter) => {
  return ExecutePublicAPI(
    "listLabDetails",
    undefined,
    undefined,
    undefined,
    filter,
    undefined,
    undefined,
    limit
  );
};

export const ListSurgeryCenter = (limit, filter) => {
  return ExecuteQueryCustom(
    "listSurgeryCenterDetails",
    undefined,
    undefined,
    undefined,
    filter,
    undefined,
    undefined,
    limit
  );
};

export const ListPublicSurgeryCenter = (limit, filter) => {
  return ExecutePublicAPI(
    "listSurgeryCenterDetails",
    undefined,
    undefined,
    undefined,
    filter,
    undefined,
    undefined,
    limit
  );
};

export const ListDoctorByName = (limit, filter) => {
  return ExecuteQueryCustom(
    "listDoctorProfiles",
    undefined,
    undefined,
    undefined,
    filter,
    undefined,
    undefined,
    limit
  );
};

export const ListPromotionalPartners = (limit, filter) => {
  return ExecuteQueryCustom(
    "listPromotionalPartners",
    undefined,
    undefined,
    undefined,
    filter,
    undefined,
    undefined,
    limit
  );
};

export const ListNearByDoctor = (
  limit,
  location,
  areaOfSpeciality,
  specialty,
  surgeryCenter
) => {
  return ExecuteQueryCustom(
    "nearByDoctors",
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    limit,
    undefined,
    location,
    areaOfSpeciality,
    undefined,
    undefined,
    undefined,
    specialty,
    surgeryCenter
  );
};

export const ListNearBySurgeryCenter = (
  limit,
  location,
  surgeryCenterSpeciality,
  specialty
) => {
  return ExecuteQueryCustom(
    "nearBySurgeryCenters",
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    limit,
    undefined,
    location,
    undefined,
    surgeryCenterSpeciality,
    undefined,
    undefined,
    specialty
  );
};

export const ListNearByImagingCenters = (limit, location, icSpecialities) => {
  return ExecuteQueryCustom(
    "nearByImagingCenters",
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    limit,
    undefined,
    location,
    undefined,
    undefined,
    icSpecialities
  );
};

export const ListNearByLabs = (limit, location, labType) => {
  return ExecuteQueryCustom(
    "nearByLabs",
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    limit,
    undefined,
    location,
    undefined,
    undefined,
    undefined,
    labType
  );
};

export const ListNearByPhysicalTherapies = (limit, location) => {
  return ExecuteQueryCustom(
    "nearByPhysicalTherapies",
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    limit,
    undefined,
    location,
    undefined,
    undefined,
    undefined,
    undefined
  );
};

export const ListNearByInfusionCenters = (limit, location) => {
  return ExecuteQueryCustom(
    "nearByInfusionCenters",
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    limit,
    undefined,
    location,
    undefined,
    undefined,
    undefined,
    undefined
  );
};

export const ListDoctorAppointments = (limit, filter) => {
  return ExecuteQueryCustom(
    "listDoctorAppointments",
    undefined,
    undefined,
    undefined,
    filter,
    undefined,
    undefined,
    limit
  );
};

export const ListICAppointments = (limit, filter) => {
  return ExecuteQueryCustom(
    "listIcAppointments",
    undefined,
    undefined,
    undefined,
    filter,
    undefined,
    undefined,
    limit
  );
};

export const ListPTAppointments = (limit, filter) => {
  return ExecuteQueryCustom(
    "listPtAppointments",
    undefined,
    undefined,
    undefined,
    filter,
    undefined,
    undefined,
    limit
  );
};

export const ListInfusionAppointments = (limit, filter) => {
  return ExecuteQueryCustom(
    "listInfusionAppointments",
    undefined,
    undefined,
    undefined,
    filter,
    undefined,
    undefined,
    limit
  );
};

export const ListPublicDoctors = (limit, filter) => {
  return ExecutePublicAPI(
    "listDoctorProfiles",
    undefined,
    undefined,
    undefined,
    filter,
    undefined,
    undefined,
    limit
  );
};

export const ListPtCenterByName = (limit, filter) => {
  return ExecuteQueryCustom(
    "listPhysicalTherapyDetails",
    undefined,
    undefined,
    undefined,
    filter,
    undefined,
    undefined,
    limit
  );
};

export const ListInfusionCenter = (limit, filter) => {
  return ExecuteQueryCustom(
    "listInfusionCenters",
    undefined,
    undefined,
    undefined,
    filter,
    undefined,
    undefined,
    limit
  );
};

export const ListPublicPTCenter = (limit, filter) => {
  return ExecutePublicAPI(
    "listPhysicalTherapyDetails",
    undefined,
    undefined,
    undefined,
    filter,
    undefined,
    undefined,
    limit
  );
};

export const ListPRocedureByName = (byName, filter, limit) => {
  return ExecuteQueryCustom(
    "proceduresByName",
    undefined,
    byName,
    undefined,
    filter,
    undefined,
    undefined,
    limit
  );
};

export const GetPublicProcedure = (procedureName) => {
  return ExecuteQueryCustom(
    "procedureByName",
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    procedureName
  );
};

export const ListProcedure = (limit, filter) => {
  return ExecuteQueryCustom(
    "listProcedures",
    undefined,
    undefined,
    undefined,
    filter,
    undefined,
    undefined,
    limit
  );
};

export const ListICcenterByName = (limit, filter) => {
  return ExecuteQueryCustom(
    "listImgagingCenterDetails",
    undefined,
    undefined,
    undefined,
    filter,
    undefined,
    undefined,
    limit
  );
};

export const ListPublicIC = (limit, filter) => {
  return ExecutePublicAPI(
    "listImgagingCenterDetails",
    undefined,
    undefined,
    undefined,
    filter,
    undefined,
    undefined,
    limit
  );
};

export const ListRewardSubmissions = (filter) => {
  return ExecuteQueryCustom(
    "listRewardSubmissions",
    undefined,
    undefined,
    undefined,
    filter
  );
};

export const CreateLab = (inputData) => {
  return ExecuteMutation("createLabDetails", inputData);
};

export const CreateIcAppointment = (inputData) => {
  return ExecuteMutation("createIcAppointment", inputData);
};

export const CreateInfusionAppointment = (inputData) => {
  return ExecuteMutation("createInfusionAppointment", inputData);
};

export const DeleteIC = (inputData) => {
  return ExecuteMutation("deleteImgagingCenterDetails", inputData);
};

export const DeleteProcedure = (inputData) => {
  return ExecuteMutation("deleteProcedures", inputData);
};

export const DeleteSurgery = (inputData) => {
  return ExecuteMutation("deleteSurgeryCenterDetails", inputData);
};

export const DeleteDoctor = (inputData) => {
  return ExecuteMutation("deleteDoctorProfile", inputData);
};

export const DeleteLab = (inputData) => {
  return ExecuteMutation("deleteLabDetails", inputData);
};

export const DeletePTCenter = (inputData) => {
  return ExecuteMutation("deletePhysicalTherapyDetails", inputData);
};

export const DeleteInfusionCenter = (inputData) => {
  return ExecuteMutation("deleteInfusionCenter", inputData);
};

export const CreatePhysicalTherapyDetails = (inputData) => {
  return ExecuteMutation("createPhysicalTherapyDetails", inputData);
};

export const CreateInfusionCenters = (inputData) => {
  return ExecuteMutation("createInfusionCenter", inputData);
};

export const CreateProcedure = (inputData) => {
  return ExecuteMutation("createProcedures", inputData);
};

export const CreateSurgicalCenter = (inputData) => {
  return ExecuteMutation("createSurgeryCenterDetails", inputData);
};

export const CreateDoctorProfile = (inputData) => {
  return ExecuteMutation("createDoctorProfile", inputData);
};

export const CreatePromotionalPartner = (inputData) => {
  return ExecuteMutation("createPromotionalPartner", inputData);
};

export const CreateIcCenter = (inputData) => {
  return ExecuteMutation("createImgagingCenterDetails", inputData);
};

export const GetEmployeeDetails = (id) => {
  return ExecuteCustomQueries("getEmployeeRegi", id);
};

export const GetEligibleMemberDetails = (id) => {
  return ExecuteQuery("getEligibleMembers", id);
};

export const GetImgagingCenterDetails = (id) => {
  return ExecuteQuery("getImgagingCenterDetails", id);
};

export const GetDoctorDetails = (id) => {
  return ExecuteQuery("getDoctorProfile", id);
};

export const GetPartners = (id) => {
  return ExecuteQuery("getPromotionalPartner", id);
};

export const GetLabDetails = (id) => {
  return ExecuteQuery("getLabDetails", id);
};

export const GetSurgeryCenterDetails = (id) => {
  return ExecuteQuery("getSurgeryCenterDetails", id);
};

export const GetMemberProfile = (id) => {
  return ExecuteQuery("getMember", id);
};

export const GetPhysicalTherapyDetails = (id) => {
  return ExecuteQuery("getPhysicalTherapyDetails", id);
};

export const GetInfusionDetails = (id) => {
  return ExecuteQuery("getInfusionCenter", id);
};

export const GetProcedures = (id) => {
  return ExecuteQuery("getProcedures", id);
};

export const GetDoctorAppointment = (id) => {
  return ExecuteQuery("getDoctorAppointment", id);
};

export const GetPTAppointment = (id) => {
  return ExecuteQuery("getPtAppointment", id);
};

export const GetInfusionAppointment = (id) => {
  return ExecuteQuery("getInfusionAppointment", id);
};

export const GetICAppointment = (id) => {
  return ExecuteQuery("getIcAppointment", id);
};

export const GetCompanyRegi = (id) => {
  return ExecuteQuery("getCompanyRegi", id);
};

export const UpdateEmployeeProfile = (inputData) => {
  return ExecuteMutation("updateEmployeeProfile", inputData);
};

export const UpdateMemberProfile = (inputData) => {
  return ExecuteMutation("updateMemberProfile", inputData);
};

export const UpdateEmployeeRegi = (inputData) => {
  return ExecuteMutation("updateEmployeeRegi", inputData);
};

export const UpdateMembers = (inputData) => {
  return ExecuteMutation("updateMember", inputData);
};

export const UpdateProcedures = (inputData) => {
  return ExecuteMutation("updateProcedures", inputData);
};

export const UpdatePhysicalTherapyDetails = (inputData) => {
  return ExecuteMutation("updatePhysicalTherapyDetails", inputData);
};

export const UpdateInfusionCenter = (inputData) => {
  return ExecuteMutation("updateInfusionCenter", inputData);
};

export const UpdateDoctorProfile = (inputData) => {
  return ExecuteMutation("updateDoctorProfile", inputData);
};

export const UpdatePartner = (inputData) => {
  return ExecuteMutation("updatePromotionalPartner", inputData);
};

export const UpdateChat = (inputData) => {
  return ExecuteMutation("updateChat", inputData);
};

export const UpdateEligibleMember = (inputData) => {
  return ExecuteMutation("updateEligibleMembers", inputData);
};

export const UpdateLabDetails = (inputData) => {
  return ExecuteMutation("updateLabDetails", inputData);
};

export const UpdateImgagingCenterDetails = (inputData) => {
  return ExecuteMutation("updateImgagingCenterDetails", inputData);
};

export const UpdateSurgeryCenterDetails = (inputData) => {
  return ExecuteMutation("updateSurgeryCenterDetails", inputData);
};

export const UpdateDocAppointment = (inputData) => {
  return ExecuteMutation("updateDoctorAppointment", inputData);
};

export const UpdatePTAppointment = (inputData) => {
  return ExecuteMutation("updatePtAppointment", inputData);
};

export const UpdateInfusionAppointment = (inputData) => {
  return ExecuteMutation("updateInfusionAppointment", inputData);
};

export const UpdateICAppointment = (inputData) => {
  return ExecuteMutation("updateIcAppointment", inputData);
};

export const UpdateRewardSubmissions = (inputData) => {
  return ExecuteMutation("updateRewardSubmissions", inputData);
};

export const GetRewardSubmissions = (id) => {
  return ExecuteQuery("getRewardSubmissions", id);
};

export const UpdateCompanyRegi = (inputData) => {
  return ExecuteMutation("updateCompanyRegi", inputData);
};

export const UpdateCompanyProfile = (inputData) => {
  return ExecuteMutation("updateCompanyProfile", inputData);
};

export const ListEmployeesRegis = (filter, limit) => {
  return ExecuteCustom(
    "listEmployeeRegis",
    undefined,
    undefined,
    undefined,
    filter,
    undefined,
    undefined,
    limit
  );
};

export const listMembersUnderDoctor = (filter, limit) => {
  return ExecuteCustomQuery("listMembersCustom", filter, limit, undefined);
};

export const ListCutomEmployeesRegis = (filter, limit) => {
  return ExecuteCustom(
    "listEmployeeRegis",
    undefined,
    undefined,
    undefined,
    filter,
    undefined,
    undefined,
    limit
  );
};

export const ListMembers = (filter, limit) => {
  return ExecuteQueryCustom(
    "listMembers",
    undefined,
    undefined,
    undefined,
    filter,
    undefined,
    undefined,
    limit
  );
};

export const ListEligibleMembers = (filter, limit) => {
  return ExecuteQueryCustom(
    "listEligibleMembers",
    undefined,
    undefined,
    undefined,
    filter,
    undefined,
    undefined,
    limit
  );
};

export const ListEmployeeProfiles = (filter) => {
  return ExecuteQueryCustom(
    "listEmployeeProfiles",
    undefined,
    undefined,
    undefined,
    filter
  );
};

export const ListCompanyRegis = (filter) => {
  return ExecuteQueryCustom(
    "listCompanyRegis",
    undefined,
    undefined,
    undefined,
    filter
  );
};

export const ListNotRegiEmployees = (filter) => {
  return ExecuteQueryCustom(
    "listSelfInsuredEmps",
    undefined,
    undefined,
    undefined,
    filter
  );
};

export const ListBillingPlans = (filter) => {
  return ExecuteQueryCustom(
    "listPlans",
    undefined,
    undefined,
    undefined,
    filter
  );
};

export const ListBillingPlansSelfServing = (filter) => {
  return ExecuteQueryCustom(
    "listPlans",
    undefined,
    undefined,
    undefined,
    filter,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    "API_KEY"
  );
};
// export const ListUserChats = () => {
//   return ExecuteQueryCustom(
//     "listUserChats"
//     // undefined,
//     // undefined,
//     // undefined,
//     // filter
//   );
// };

export const ListUserChats = (filter, limit) => {
  return ExecuteCustom(
    "listChats",
    undefined,
    undefined,
    undefined,
    filter,
    undefined,
    undefined,
    limit
  );
};
