// query MyQuery($id: ID = “”) {
//     getCompanyRegi(id: $id) {
//       name
//     }
//   }

export const getCompanyRegi = /* GraphQL */ `
  query GetCompanyRegi($id: ID!) {
    getCompanyRegi(id: $id) {
      name
    }
  }
`;

export const updateEpcEmployerDocuments = `
  mutation UpdateEpcEmployerProfile(
    $input: UpdateEpcEmployerProfileInput!
    $condition: ModelEpcEmployerProfileConditionInput
  ) {
    updateEpcEmployerProfile(input: $input, condition: $condition) {
      id
      documents {
        name
        description
        key
        __typename
      }
      updatedAt
    }
  }
`;

export const getEpcEmployer = /* GraphQL */ `
  query GetEpcEmployer($id: ID!) {
    getEpcEmployer(id: $id) {
      id
      profileId
      profile {
        name
        id
        phone
        form {
          formLink
          formStatus
        }
        documents {
          name
          description
          key
        }
        streetAddress
        city
        state
        website
        zip
        email
      }
    }
  }
`;
export const getEpcEmployerName = /* GraphQL */ `
  query GetEpcEmployer($id: ID!) {
    getEpcEmployer(id: $id) {
      id
      profileId
      searchByName
      email
      profile {
        form {
          formLink
          formStatus
        }
      }
    }
  }
`;

export const listEpcEmployers = /* GraphQL */ `
  query ListEpcEmployers(
    $filter: ModelEpcEmployerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEpcEmployers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        searchByName
        profileId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listEmployeeRegis = /* GraphQL */ `
  query ListEmployeeRegis(
    $filter: ModelEmployeeRegiFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployeeRegis(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        searchByName
        employeeEmail
        emailType
        verified
        role
        userType
        emailID
        inList
        lastLogin
        billing_status
        total_user
        members(limit: 99999) {
          nextToken
          items {
            id
            firstName
            lastName
            emailID
            billing_status
            accountStatus
            lastLogin
          }
        }
        DpcBillingID
        DpcDetails {
          id
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
          metadata
          createdAt
          updatedAt
          __typename
        }
        companyID {
          id
          approved
          name
          searchByName
          url
          phoneNumber
          email
          emailDomain
          profileID
          billing_status
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
          metadata
          createdAt
          updatedAt
          companyRegiUnSubscribeIDId
          owner
        }
        unSubscribeID {
          id
          reason
          createdAt
          updatedAt
          unSubscribeEmployeeId
          unSubscribeMemberId
          unSubscribeCompanyId
          owner
        }
        profile {
          email
          profilePhoto
          profileCompleted
          firstName
          lastName
          searchByName
          dob
          insuranceCarrier
          planID
          editStatus
          editRequested
          memberID
          streetAdress
          city
          state
          zip
          phone
          gender
          createdAt
          updatedAt
          owner
        }
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        plan {
          id
          name
          planDetails
          createdAt
          updatedAt
        }
        metadata
        createdAt
        updatedAt
        companyRegiEmployeeId
        employeeRegiUnSubscribeIDId
        owner
      }
      nextToken
    }
  }
`;

export const getEmployeeRegi = /* GraphQL */ `
  query GetEmployeeRegi($id: ID!) {
    getEmployeeRegi(id: $id) {
      id
      firstName
      lastName
      searchByName
      employeeEmail
      emailType
      verified
      role
      userType
      emailID
      inList
      lastLogin
      billing_status
      total_user
      members(limit: 9999) {
        items {
          id
          lastName
          firstName
          emailID
        }
      }
      dpcMembers(limit: 9999) {
        items {
          id
          lastName
          firstName
          emailID
          dpcDoctorID
          dpcDoctor {
            doctorName
          }
          DpcBillingID
          DpcDetails {
            id
            accountStatus
            stripeCustomerID
            stripeSubscriptionID
            stripePriceID
            planID
            metadata
            createdAt
            updatedAt
            __typename
          }
        }
      }
      DpcBillingID
      DpcDetails {
        id
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        createdAt
        updatedAt
        __typename
      }
      companyID {
        id
        approved
        name
        searchByName
        url
        phoneNumber
        email
        emailDomain
        profileID
        billing_status
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        createdAt
        updatedAt
        companyRegiUnSubscribeIDId
        owner
      }
      dpcDoctorID
      dpcDoctor {
        doctorName
      }
      unSubscribeID {
        id
        reason
        createdAt
        updatedAt
        unSubscribeEmployeeId
        unSubscribeMemberId
        unSubscribeCompanyId
        owner
      }
      profile {
        email
        profilePhoto
        profileCompleted
        firstName
        lastName
        searchByName
        dob
        insuranceCarrier
        planID
        editStatus
        editRequested
        memberID
        streetAdress
        city
        state
        zip
        phone
        gender
        createdAt
        updatedAt
        owner
      }
      accountStatus
      stripeCustomerID
      stripeSubscriptionID
      stripePriceID
      planID
      plan {
        id
        name
        planDetails
        createdAt
        updatedAt
      }
      metadata
      createdAt
      updatedAt
      companyRegiEmployeeId
      employeeRegiUnSubscribeIDId
      owner
    }
  }
`;

export const listDoctorCustom = /* GraphQL */ `
  query ListDoctorProfiles(
    $filter: ModelDoctorProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDoctorProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        physicianID
        doctorName
        searchByName
        byName
        doctorProfilePhoto
        doctorDegree
        doctorEmail
        aboutDoctor
        doctorEducation
        doctorOfficeAdd
        areaOfSpeciality
        bodyArea
        specialities
        doctorOfficeCity
        doctorOfficeState
        doctorOfficeZip
        doctorOfficePhone
        doctorOfficeFax
        doctorPracticeName
        doctorWebsite
        doctorFacility
        surgeryCenter
        googleRating
        facebookRating
        testimonial
        activeOnPortal
        distance
        specialty
        startDate
        isDpcDoctor
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDoctorCustom = `
query GetDoctorProfile($id: ID!) {
  getDoctorProfile(id: $id) {
    id
    physicianID
    doctorName
    searchByName
    byName
    doctorProfilePhoto
    doctorDegree
    doctorEmail
    aboutDoctor
    doctorEducation
    doctorOfficeAdd
    areaOfSpeciality
    bodyArea
    specialities
    doctorOfficeCity
    doctorOfficeState
    doctorOfficeZip
    doctorOfficePhone
    doctorOfficeFax
    doctorPracticeName
    doctorWebsite
    doctorFacility
    surgeryCenter
    googleRating
    facebookRating
    testimonial
    activeOnPortal
    distance
    specialty
    startDate
    isDpcDoctor
    createdAt
    updatedAt
    __typename
  }
}
`;
export const listChats = /* GraphQL */ `
  query ListChats(
    $filter: ModelChatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChats(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        senderID
        senderDetails {
          id
          firstName
          lastName
          fcmToken
          searchByName
          employeeEmail
          emailType
          verified
          role
          userType
          emailID
          inList
          lastLogin
          billing_status
          total_user
          promotionalID
          dpcDoctorID
          DpcBillingID
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
          profile {
            profilePhoto
            firstName
            lastName
          }
          metadata
          createdAt
          updatedAt
          companyRegiEmployeeId
          employeeRegiUnSubscribeIDId
          owner
          __typename
        }
        admin
        adminDetails {
          id
          firstName
          lastName
          fcmToken
          searchByName
          employeeEmail
          emailType
          verified
          role
          userType
          emailID
          inList
          lastLogin
          billing_status
          total_user
          promotionalID
          dpcDoctorID
          DpcBillingID
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
          metadata
          createdAt
          updatedAt
          companyRegiEmployeeId
          employeeRegiUnSubscribeIDId
          owner
          __typename
        }
        messages(limit: 9999, sortDirection: ASC) {
          nextToken
          __typename
          items {
            id
            content
            senderID
            createdAt
            systemContent
          }
        }
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
