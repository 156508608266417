import React, { useState } from "react";
import { Button, Tabs } from "antd";
import DpcList from "./DpcList";
import "./DpcFrontPage.less";
import EmployerList from "./Employers/EmployerList";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { API } from "aws-amplify";
import { listEpcReferences } from "graphql/customEpcQuery";
import AllUsersList from "./AllUsers/AllUsersList";

export default function DpcFrontPage() {
  const [referenceData, setReferenceData] = useState(null);

  const handleDownloadPDF = async () => {
    let dataToProcess = referenceData;

    if (!referenceData) {
      const { data } = await API.graphql({
        query: listEpcReferences,
        variables: {
          filter: {
            reference: { attributeExists: true },
          },
          limit: 9999,
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      // Step 1: Extract the values and count occurrences
      dataToProcess = data?.listSelfServingUsers?.items.reduce((acc, obj) => {
        const value = obj.reference.trim();
        if (!value) return acc;
        acc[value] = (acc[value] || 0) + 1; // Increment the count
        return acc;
      }, {});

      setReferenceData(dataToProcess);
    }

    // Create a new PDF
    const doc = new jsPDF();

    // Add Title
    doc.text("Reference Count List", 14, 10);

    // Prepare data for the table
    const tableData = Object.entries(dataToProcess).map(([key, count]) => [
      key,
      count,
    ]);

    // Add a table using the autotable plugin
    doc.autoTable({
      head: [["Value", "Count"]], // Table headers
      body: tableData, // Table content
      startY: 20, // Start position for the table
    });

    // Save the PDF
    doc.save("reference-count-list.pdf");
  };

  const referralList = (
    <Button className="blue-border-btn inherit-btn" onClick={handleDownloadPDF}>
      Source of Reference
    </Button>
  );
  const items = [
    {
      key: "1",
      label: "By Doctor",
      children: <DpcList />,
    },
    {
      key: "2",
      label: "All Users",
      children: <AllUsersList />,
    },
    {
      key: "3",
      label: "Employers",
      children: <AllUsersList />,
    },
  ];
  return (
    <>
      <Tabs
        tabBarExtraContent={referralList}
        defaultActiveKey="1"
        items={items}
        size={"large"}
      />
    </>
  );
}
