export const createEpcEmployer = /* GraphQL */ `
  mutation CreateEpcEmployer(
    $input: CreateEpcEmployerInput!
    $condition: ModelEpcEmployerConditionInput
  ) {
    createEpcEmployer(input: $input, condition: $condition) {
      id
      email
      searchByName
      byName
      profileId
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const createEpcEmployerProfile = /* GraphQL */ `
  mutation CreateEpcEmployerProfile(
    $input: CreateEpcEmployerProfileInput!
    $condition: ModelEpcEmployerProfileConditionInput
  ) {
    createEpcEmployerProfile(input: $input, condition: $condition) {
      id
      email
      website
      name
      phone
      streetAddress
      zip
      city
      state
      form {
        formLink
        formStatus
        __typename
      }
      documents {
        name
        description
        key
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const updateEpcEmployer = /* GraphQL */ `
  mutation UpdateEpcEmployer(
    $input: UpdateEpcEmployerInput!
    $condition: ModelEpcEmployerConditionInput
  ) {
    updateEpcEmployer(input: $input, condition: $condition) {
      id
      email
      searchByName
      byName
      profileId
      createdAt
      updatedAt
      __typename
    }
  }
`;
