import React from "react";
import comingsoon from "assets/icons/coming.png";

export default function AllUsersList() {
  return (
    <>
      <div className="no-search">
        <div className="no-search-box">
          <img src={comingsoon} alt="" width={500} />
        </div>
      </div>
    </>
  );
}
