import { Tabs } from "antd";

import React from "react";
import EmployerProfile from "./EmployerProfile";
import EpcEmployeesListing from "../Employees/EpcEmployeesListing";
// import EmployerPayments from "./EmployerPayments";

export default function EmployerDetails() {
  const items = [
    {
      key: "1",
      label: "Profile",
      children: <EmployerProfile />,
    },
    {
      key: "2",
      label: "Payments",
      children: "",
    },
    {
      key: "3",
      label: "Members",
      children: <EpcEmployeesListing />,
    },
  ];

  return (
    <>
      <Tabs defaultActiveKey="1" centered items={items} size={"large"} />
    </>
  );
}
