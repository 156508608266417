import {
  Button,
  Col,
  Row,
  Form,
  Input,
  message,
  InputNumber,
  Radio,
  Table,
  Space,
  Modal,
  Typography,
  Upload,
} from "antd";
import {
  EyeOutlined,
  DeleteOutlined,
  InboxOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { API, Auth } from "aws-amplify";
import SkeletonBlock from "utility/SkeletonBlocks";
import {
  cityPattern,
  formatNumberUS,
  streetAddressPattern,
  isUuid,
  tenDigit,
  zipPattern,
  urlPattern,
} from "utility/constant";
import SuccessModel from "utility/SuccessModel";
import { getEpcEmployer } from "graphql/customQuery";
import { updateEpcEmployerProfile } from "graphql/mutations";
import { uploadFileToS3 } from "utility/uploadFileToS3";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { updateEpcEmployer } from "graphql/customEpcMutation";
import DeleteModal from "utility/DeleteModal";

export default function EmployerProfile() {
  const { Dragger } = Upload;
  const { id: employerID } = useParams();
  const [cognitoId, setCognitoId] = useState(null);
  const [profileID, setProfileID] = useState(null);
  const [skeletonLoader, setskeletonLoader] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [uploadLoader, setUploadLoader] = useState(false);
  const [document, setDocument] = useState([]);
  const [employersData, setEmployerData] = useState([]);
  const [successModel, setSuccessModel] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);
  const [formLink, setFormLink] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [form] = Form.useForm();
  const [saveDisabled, setSaveDisabled] = useState(true);
  const navigate = useNavigate();

  const [deleteModal, setDeleteModal] = useState(false);
  const uploadFormRef = useRef();
  const deleteDocumentID = useRef(null);
  const successMessage = useRef(null);

  const handleValuesChange = () => {
    setSaveDisabled(false);
  };

  const fetchEmployerProfile = async () => {
    if (!employerID || !isUuid.test(employerID)) {
      navigate("/404", {
        state: { message: `No data found for Employer ${employerID}` },
      });
    }
    setskeletonLoader(true);
    const response = await API.graphql({
      query: getEpcEmployer,
      variables: {
        id: employerID,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    if (!response?.data) {
      navigate("/404", {
        state: { message: `No data found for Employer ${employerID}` },
      });
    }

    setProfileID(response.data?.getEpcEmployer?.profileId);
    setEmployerData(response.data?.getEpcEmployer);
    setDocument(response.data?.getEpcEmployer?.profile?.documents || []);
    setFormLink(
      response.data?.getEpcEmployer?.profile?.form?.formLink ||
        `${window.location.origin}/enhanced-primary-care/employer/${employerID}`
    );
    setskeletonLoader(false);
  };

  useEffect(() => {
    fetchEmployerProfile();
  }, [employerID]);

  const initialValues = {
    name: employersData?.profile?.name,
    phoneNumber: employersData?.profile?.phone,
    website: employersData?.profile?.website,
    formStatus: employersData?.profile?.form?.formStatus || "ON",
    formLink: formLink,
    email: employersData?.profile?.email,
    streetAddress: employersData?.profile?.streetAddress,
    city: employersData?.profile?.city,
    state: employersData?.profile?.state,
    zip: employersData?.profile?.zip,
    employerID: employersData?.id,
  };

  const updateEmployerProfile = async (values) => {
    try {
      // Set loading state
      setUpdateLoading(true);
      // Prepare input data for GraphQL mutation
      const inputData = {
        input: {
          id: profileID,
          name: values?.name,
          phone: values?.phoneNumber,
          streetAddress: values?.streetAddress,
          city: values?.city,
          state: values?.state,
          zip: values?.zip,
          website: values?.website,
          form: {
            formStatus: values?.formStatus || "ON",
          },
        },
      };
      // Perform GraphQL mutation to update employer profile
      await API.graphql({
        query: updateEpcEmployerProfile,
        variables: inputData,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      if (employersData?.searchByName !== values?.name.toLowerCase()) {
        await API.graphql({
          query: updateEpcEmployer,
          variables: {
            input: {
              id: employerID,
              searchByName: values?.name?.toLowerCase(),
            },
          },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });
      }
      // On success
      setSuccessModel(true);
      setSaveDisabled(true);
      // Hide success model after 3 seconds
      successMessage.current = "Employer Details Updated Successfully";
      setTimeout(() => {
        setSuccessModel(false);
        setTimeout(() => {
          successMessage.current = null;
        }, 1000);
      }, 3000);
    } catch (error) {
      // Handle errors
      console.error("Error updating employer profile:", error);
      messageApi.error(error.message || "Error updating data");
    } finally {
      // Ensure loading state is reset
      setUpdateLoading(false);
    }
  };

  const deleteDocument = async () => {
    try {
      setDeleteLoading(true);
      let url = `/superAdmin/moveToTrash`;
      messageApi.loading("Deleting document from the cloud ☁️");
      const requestBody = {
        body: {
          key: deleteDocumentID.current,
        },
      };
      await API.post("bazarhealthREST", url, requestBody);

      const updatedDocumentList = document.filter(
        (obj) => obj?.key !== deleteDocumentID.current
      );

      setDocument(updatedDocumentList);
      const inputData = {
        input: {
          id: profileID,
          documents: updatedDocumentList,
        },
      };
      // Perform GraphQL mutation to update employer profile
      await API.graphql({
        query: updateEpcEmployerProfile,
        variables: inputData,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      successMessage.current = "document deleted successfully!";
      setSuccessModel(true);
      setTimeout(() => {
        setSuccessModel(false);
        setTimeout(() => {
          successMessage.current = null;
        }, 1000);
      }, 3000);
    } catch (error) {
      console.log(error);
      messageApi.error("Something went wrong, deleting the document");
    } finally {
      setDeleteLoading(false);
      setDeleteModal(false);
    }
  };

  const documentColoumn = [
    {
      title: "File",
      dataIndex: "name",
      ellipsis: true,
    },
    {
      title: "Description",
      dataIndex: "description",
      ellipsis: true,
    },

    {
      title: "Action",
      dataIndex: "Action",
      className: "actions",
      render: (id, value) => {
        return (
          <>
            <Space size={20}>
              <EyeOutlined
                style={{ cursor: "pointer" }}
                onClick={async () => {
                  let url = `/superAdmin/send_presigned_url_epc`;
                  messageApi.loading("Fetching document from the cloud ☁️");
                  const requestBody = {
                    body: {
                      key: value.key,
                    },
                  };
                  let result = await API.post(
                    "bazarhealthREST",
                    url,
                    requestBody
                  );

                  window.open(result, "_blank");
                }}
              />
              <DeleteOutlined
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setDeleteModal(true);
                  deleteDocumentID.current = value.key;
                }}
              />
            </Space>
          </>
        );
      },
    },
  ];

  const props = {
    beforeUpload: (file) => {
      const maxFileSize = 25 * 1024 * 1024; //25 MB limit
      if (file.size > maxFileSize) {
        message.error(`${file.name} file upload failed (exceeds 25MB)`);
        return Upload.LIST_IGNORE;
      }
      return true;
    },

    onChange(info) {
      if (info.fileList.length === 0) {
        uploadFormRef.current.document = {};
      }
    },
    async customRequest({ file, onError, onSuccess }) {
      setTimeout(() => {
        onSuccess("ok"); // Simulate upload success for UI
      }, 0);
    },
  };

  const uploadDocument = (values) => {
    if (values?.document?.fileList.length === 0) {
      messageApi.warning("Please upload a document");
      return;
    }
    setUploadLoader(true);

    uploadFileToS3(values?.document?.file?.originFileObj)
      .then(async (res) => {
        //update the profile as well :)

        let identityId = cognitoId;
        if (!cognitoId) {
          const loggedInUser = await Auth.currentUserInfo();
          identityId = loggedInUser?.id;
          setCognitoId(loggedInUser?.id);
        }
        // Prepare input data for GraphQL mutation
        const inputData = {
          input: {
            id: profileID,
            documents: [
              {
                name: values?.name,
                description: values?.description || "-",
                key: `private/${identityId}/${res.key}`,
              },
              ...document,
            ],
          },
        };

        // Perform GraphQL mutation to update employer profile
        await API.graphql({
          query: updateEpcEmployerProfile,
          variables: inputData,
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });
        messageApi.success("Document uploaded successfully");
        setDocument(inputData?.input?.documents);
        successMessage.current = "document uploaded successfully!";
        setSuccessModel(true);
        setTimeout(() => {
          setSuccessModel(false);
          setTimeout(() => {
            successMessage.current = null;
          }, 1000);
        }, 3000);
      })
      .catch((err) => {
        console.log("ERROR", err);
      })
      .finally(() => {
        form.resetFields();
        setUploadLoader(false);
        setUploadModal(false);
      });
  };

  return (
    <>
      {contextHolder}

      <Row justify="center" className="update-form">
        <Col span={16} md={16} xs={24} sm={24}>
          {skeletonLoader ? (
            <>
              <Row gutter={[20, 20]} className="mt-3">
                <SkeletonBlock style={{ width: 700, height: 50 }} />
                <SkeletonBlock style={{ width: 700, height: 50 }} />
                <SkeletonBlock style={{ width: 700, height: 50 }} />
                <SkeletonBlock style={{ width: 700, height: 50 }} />
                <SkeletonBlock style={{ width: 700, height: 50 }} />
                <SkeletonBlock style={{ width: 700, height: 50 }} />
                <SkeletonBlock style={{ width: 700, height: 200 }} />
              </Row>
            </>
          ) : (
            <>
              <Form
                name="basic"
                initialValues={initialValues}
                onFinish={updateEmployerProfile}
                autoComplete="off"
                layout="vertical"
                className="mt-30"
                onValuesChange={handleValuesChange}
              >
                <Row gutter={20}>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item label="Employer name" name="name">
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item label="Employer email id" name="email">
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      label="Employer url"
                      name="website"
                      rules={[
                        {
                          pattern: urlPattern,
                          message: "Please input valid url",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      label="Employer phone number"
                      name="phoneNumber"
                      rules={[
                        {
                          pattern: tenDigit,
                          message: "Please enter a valid phone number",
                        },
                      ]}
                    >
                      <InputNumber
                        controls={false}
                        formatter={(value) => formatNumberUS(value)}
                        parser={(value) => {
                          const cleanedValue = value.replace(/\D/g, "");
                          return cleanedValue.slice(0, 10);
                        }}
                        style={{ width: "100%", borderRadius: "5px" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item label="Form link" name="formLink">
                      <Input
                        disabled
                        suffix={
                          <>
                            <CopyToClipboard
                              text={formLink}
                              onCopy={() =>
                                message.success(`Link copied to clipboard`)
                              }
                            >
                              <CopyOutlined />
                            </CopyToClipboard>
                          </>
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item label="Form Status" name="formStatus">
                      <Radio.Group onChange={() => {}}>
                        <Radio value={"ON"}>On</Radio>
                        <Radio value={"OFF"}>Off</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      label="Street address"
                      name="streetAddress"
                      rules={[
                        {
                          pattern: streetAddressPattern,
                          message: "Please enter a valid street address",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      label="City"
                      name="city"
                      rules={[
                        {
                          pattern: cityPattern,
                          message: "Please enter a valid city name",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      label="State"
                      name="state"
                      rules={[
                        {
                          pattern: cityPattern,
                          message: "Please enter a valid state name",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      label="Zip"
                      name="zip"
                      rules={[
                        {
                          pattern: zipPattern,
                          message: "Please enter a valid ZIP code",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "0 10px 0 10px", // Align vertically in the center
                    }}
                  >
                    <span
                      style={{
                        color: "#646464",
                        fontWeight: "600",
                        lineHeight: "21px",
                        fontSize: "15px",
                      }}
                    >
                      Documents{" "}
                      <i>
                        {" "}
                        {document.length > 0
                          ? "(Below mentioned documents are already uploaded)"
                          : ""}
                      </i>
                    </span>

                    <Button
                      size="large"
                      className="theme-btn"
                      style={{
                        alignSelf: "center",
                        border: "1px solid #00274c",
                        background: "transparent",
                      }}
                      onClick={() => setUploadModal(true)}
                    >
                      <span
                        style={{
                          color: "#00274c",
                          lineHeight: "1px",
                          fontSize: "14px",
                        }}
                      >
                        Upload
                      </span>
                    </Button>
                  </div>
                  <Table
                    columns={documentColoumn}
                    className="common-table"
                    pagination={false}
                    scroll={{
                      y: 160,
                    }}
                    dataSource={document}
                  />
                  <Col span={24} md={24} xs={24} sm={24} className="center-btn">
                    <Button
                      size="large"
                      className="theme-btn mt-20"
                      htmlType="submit"
                      loading={updateLoading}
                      disabled={saveDisabled}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </Form>
            </>
          )}
        </Col>
      </Row>
      <SuccessModel
        message={successMessage.current || "Task done successfully!"}
        open={successModel}
      />
      <Modal
        className="form-modal"
        centered
        footer={null}
        width={700}
        title={
          <Typography.Title level={4} className="modal-title">
            {`Upload document ${
              employersData?.name ? `for ${employersData?.name} ` : ""
            }`}
          </Typography.Title>
        }
        open={uploadModal}
        // open={true}
        // onOk={handleOk}
        onCancel={() => {
          setUploadModal(false);
          form.resetFields();
        }}
      >
        <Form
          name="basic"
          onFinish={uploadDocument}
          ref={uploadFormRef}
          autoComplete="off"
          layout="vertical"
          form={form}
        >
          <Row gutter={20}>
            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item
                label="File name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please enter file name",
                  },
                ]}
              >
                <Input maxLength={20} showCount />
              </Form.Item>
            </Col>

            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item
                label="Description"
                name="description"
                rules={[
                  {
                    required: false,
                    message: "Please enter employer email id",
                  },
                ]}
              >
                <Input maxLength={200} showCount />
              </Form.Item>
            </Col>

            <Col span={24} md={24} xs={24} sm={24}>
              <Form.Item
                label=""
                name="document"
                rules={[
                  {
                    required: true,
                    message: "Please upload a Document",
                  },
                ]}
              >
                <Dragger
                  {...props}
                  maxCount={1}
                  accept=".xlsx, .xls, .csv, .txt, .ods, .pdf, .docx, .doc, .txt, .rtf, .odt, .jpg, .jpeg, .png"
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag a Document to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    Uploading more than a document will replace the exisitng
                    one.
                  </p>
                </Dragger>
              </Form.Item>
            </Col>

            <Col span={24} md={24} xs={24} sm={24} className="center-btn">
              <Button
                size="large"
                className="theme-btn"
                htmlType="submit"
                loading={uploadLoader}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      <DeleteModal
        content="Are you sure you want to delete the selected document?"
        open={deleteModal}
        onClick={deleteDocument}
        buttonLoading={deleteLoading}
        onOk={() => {
          setDeleteModal(false);
          deleteDocumentID.current = null;
        }}
        onCancel={() => {
          setDeleteModal(false);
          deleteDocumentID.current = null;
        }}
        buttonText="Yes"
      />
    </>
  );
}
