import {
  Row,
  Typography,
  Input,
  Button,
  Table,
  Modal,
  Form,
  Select,
  Col,
  message,
  Dropdown,
  Menu,
  Space,
} from "antd";
import React, { useEffect, useState } from "react";
import successDelete from "assets/icons/successDelete.svg";
import deleteIcon from "assets/icons/delete.svg";
import { MoreOutlined, SearchOutlined } from "@ant-design/icons";
import exportCSV from "assets/icons/exportcsv.svg";
import { API } from "aws-amplify";
import { disableSubmitButton, emailPattern } from "utility/constant";
import { useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import { CSVLink } from "react-csv";
import search from "utility/search";
import TableSkeleton from "utility/TableSkeleton";
import SuccessModel from "utility/SuccessModel";
import { employeeByEmployer } from "graphql/queries";

export default function EpcEmployeesListing() {
  const { id: employerID } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [employers, setEmployers] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [unregiEmpLoading, setUnregiEmpLoading] = useState(false);
  const [unregisterEmp, setUnregisterEmp] = useState([]);
  const [deleteModelPrompt, setDeleteModelPrompt] = useState(false);
  const [indDeleteLoading, setIndDeleteLoading] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [unregisterEmpDataSource, setUnregisterEmpDataSource] =
    useState(unregisterEmp);
  const [createLoading, setCreateLoading] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const [submitDisabled, setSubmitDisabled] = useState(true);
  const handleFormChange = disableSubmitButton(["employeeEmail", "companyID"])(
    form,
    setSubmitDisabled
  );

  //for deleting individual users
  const deleteUser = async (id) => {
    try {
      setIndDeleteLoading(true);
      const myInit = {
        body: {
          id: [deleteId[0]],
          type: deleteId[1],
        },
      };
      await API.post("bazarhealthREST", `/superAdmin/delete_employee`, myInit);
      const newArray = unregisterEmpDataSource.filter(function (obj) {
        return obj.key !== deleteId;
      });
      setIndDeleteLoading(false);
      setUnregisterEmpDataSource(newArray);
      setDeleteId("");
      //   fetchNotRegisteredEmployee();
      setDeleteModelPrompt(false);
      setDeleteSuccess(true);
    } catch (error) {
      console.log(error);
      messageApi.open({
        key: "delete",
        type: "error",
        content: "Error Deleting Member",
        duration: 5,
      });
      setIndDeleteLoading(false);
      setDeleteSuccess(false);
      setDeleteModelPrompt(false);
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
    form.resetFields();
    setSubmitDisabled(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
    setSubmitDisabled(true);
    setDeleteModelPrompt(false);
    setDeleteId("");
  };

  const setNotRegiEmployeesRow = function (row) {
    return {
      key: row?.id,
      Action: row?.id,
      Username: row?.emailID ? row?.emailID : "-",
      searchByName: row?.searchByName,
      FirstName: row?.firstName || "-",
      LastName: row?.lastName || "-",
    };
  };

  const fetchEmployees = useCallback(async () => {
    setUnregiEmpLoading(true);
    console.log(employerID);
    const inputData = {
      epcEmployerID: employerID,
      sortDirection: "DESC",
    };
    const response = await API.graphql({
      query: employeeByEmployer,
      variables: inputData,
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });

    const notRegisteredData = response?.data?.employeeByEmployer?.items.map(
      (row) => setNotRegiEmployeesRow(row)
    );

    setUnregisterEmp(notRegisteredData);
    setUnregisterEmpDataSource(notRegisteredData);
    setUnregiEmpLoading(false);
    setCsvData(notRegisteredData);
  }, []);

  useEffect(() => {
    fetchEmployees();
  }, [fetchEmployees]);

  const handleUnregiEmpSearch = async (e) => {
    const currValue = e.target.value;
    const searchFields = ["Username", "FirstName", "LastName"];
    const filteredData = search(unregisterEmp, currValue, searchFields);
    setUnregisterEmpDataSource(filteredData);
  };

  const createEmployee = async (values) => {
    try {
      setCreateLoading(true);
      const myInit = {
        body: {
          companyId: values?.companyID,
          email: values?.employeeEmail?.toLowerCase(),
          firstName: values?.firstName,
          lastName: values?.lastName,
        },
      };

      await API.post("bazarhealthREST", `/superAdmin/invite_employee`, myInit);
      // fetchNotRegisteredEmployee();
      setCreateLoading(false);
      form.resetFields();
      setIsModalOpen(false);
      setSubmitDisabled(true);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: " An account with the given email already exists.",
      });
      setCreateLoading(false);
    }
  };

  const column = [
    {
      title: "Member Email",
      dataIndex: "Username",
    },
    {
      title: "First Name",
      dataIndex: "FirstName",
    },
    {
      title: "Last Name",
      dataIndex: "LastName",
    },

    {
      title: "",
      dataIndex: "Action",
      className: "actions",
      render: (id, record) => {
        return (
          <Dropdown
            disabled
            overlay={
              <Menu>
                <Menu.Item key="1">
                  <Link
                    disabled
                    rel="noopener noreferrer"
                    to="/member-detail"
                    state={{
                      id: [id, record?.Payer],
                      member: record?.group,
                    }}
                  >
                    Edit
                  </Link>
                </Menu.Item>
                {/* Not Giving delete permission for HPI */}
                {console.log(record)}
                <Menu.Item key="2">
                  <Link
                    disabled
                    rel="noopener noreferrer"
                    onClick={() => {
                      setDeleteModelPrompt(true);
                      setDeleteId([id, record?.Role]);
                    }}
                  >
                    Delete
                  </Link>
                </Menu.Item>
              </Menu>
            }
            overlayClassName="download-btn"
            placement="bottomRight"
          >
            <MoreOutlined />
          </Dropdown>
        );
      },
    },
  ];

  const headers = [
    { label: "Member Email", key: "email" },
    { label: "First Name", key: "FirstName" },
    { label: "Last Name", key: "LastName" },
  ];

  const csvExp = csvData.map((row) => ({
    email: row?.Username,
    FirstName: row?.FirstName ? row?.FirstName : "-",
    LastName: row?.LastName ? row?.LastName : "-",
  }));

  return (
    <div>
      {contextHolder}
      <Row justify="space-between table-header">
        <Row align="middle">
          <Input
            prefix={<SearchOutlined />}
            placeholder="Search"
            className="searchbar"
            onChange={handleUnregiEmpSearch}
            style={{ width: 250 }}
          />
        </Row>

        <Row className="gap-10 mtr-20 filter-dropdown-list">
          <CSVLink
            data={csvExp}
            headers={headers}
            filename={"Employees.csv"}
            className="export-btn"
          >
            <img src={exportCSV} alt="" className="action-icons" />
          </CSVLink>

          <Button
            className="blue-btn"
            onClick={showModal}
            style={{ marginLeft: 0, height: "inherit" }}
            disabled
          >
            Add Member
          </Button>
        </Row>
      </Row>

      {unregiEmpLoading ? (
        <TableSkeleton />
      ) : (
        <Table
          columns={column}
          dataSource={unregisterEmpDataSource}
          className="common-table"
          scroll={{ x: 992 }}
          pagination={{ position: ["", "bottomCenter"] }}
        />
      )}

      <Modal
        className="form-modal"
        centered
        footer={null}
        width={700}
        title={
          <Typography.Title level={3} className="modal-title">
            Add Member
          </Typography.Title>
        }
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={createEmployee}
          autoComplete="off"
          layout="vertical"
          form={form}
          onFieldsChange={handleFormChange}
        >
          <Row gutter={20}>
            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item
                label="Member Email"
                name="employeeEmail"
                rules={[
                  {
                    required: true,
                    message: "Please input member email",
                  },
                  {
                    pattern: emailPattern,
                    message: "Please enter a valid email",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item
                label="Employer"
                name="companyID"
                rules={[
                  {
                    required: true,
                    message: "Please select any employer",
                  },
                ]}
              >
                <Select allowClear placeholder="Select Employer">
                  {employers.map((item) => (
                    <Select.Option
                      value={item.id}
                    >{`${item.name}`}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item
                label="First name"
                name="firstName"
                rules={[
                  {
                    pattern: /^[a-zA-Z]+$/,
                    message: "Please enter a valid first name",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item
                label="Last lame"
                name="lastName"
                rules={[
                  {
                    pattern: /^[a-zA-Z]+$/,
                    message: "Please enter a valid last name",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col
              span={12}
              md={24}
              xs={24}
              sm={24}
              style={{ textAlign: "center" }}
            >
              <Button
                className="theme-btn"
                htmlType="submit"
                loading={createLoading}
                disabled={submitDisabled}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        title="Basic Modal"
        open={deleteModelPrompt}
        onOk={handleOk}
        centered
        onCancel={handleCancel}
        className="reward-success-modal "
        footer={null}
      >
        <img src={deleteIcon} alt="Delete Img" />
        <h6>Are you sure ?</h6>
        <p>If Yes, it will delete an member permanently.</p>

        <Space>
          <Button
            type="primary"
            className="theme-btn"
            style={{ width: "106px" }}
            onClick={deleteUser}
            loading={indDeleteLoading}
          >
            Yes
          </Button>
          <Button
            type="primary"
            className="theme-btn "
            onClick={handleCancel}
            style={{ width: "106px" }}
          >
            Cancel
          </Button>
        </Space>
      </Modal>
      <SuccessModel
        message="Delete Successful!"
        open={deleteSuccess}
        imageSrc={successDelete}
      />
    </div>
  );
}
